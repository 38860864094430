import React, { useState, useEffect, Fragment, useContext } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import Typography from "@mui/material/Typography";
import UploadZone from "../components/uploadZone";
import InfoPage from "../components/InfoPage";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import { SessionContext } from "../context/SessionContext";
import { NavbarContext } from "../context/NavbarContext";
import Switch from "@mui/material/Switch";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import Tooltip from "@mui/material/Tooltip";
import { readAndCompressImage } from "browser-image-resizer";
import { useTranslation } from "react-i18next";
import LogIn from "./LogIn";
import LanguageSelector from "../components/LanguageSelector";
import { ExpandMore } from "@mui/icons-material";
import * as exifr from "exifr";
import { addCommas } from "../utils";

function Upload({ supabase }) {
  const maximumMaxKeywords = 200;
  const minimumMaxKeywords = 5;

  const maximumMinKeywords = 40;
  const minimumMinKeywords = 5;

  const maximumMaxTitle = 500;
  const minimumMaxTitle = 30;

  const maximumMinTitle = 200;
  const minimumMinTitle = 5;

  const maximumMaxDescription = 500;
  const minimumMaxDescription = 30;

  const maximumMinDescription = 200;
  const minimumMinDescription = 5;

  const { t } = useTranslation();
  const [inputFiles, setInputFiles] = useState(null);
  const [uploaded, setUploaded] = useState(0);
  const [queue, setQueue] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const fileTypes = ["JPG", "PNG", "JPEG", "WEBP", "SVG", "MP4", "MOV", "AVI"];
  const [resetUploadState, setResetUploadState] = useState(false);
  const [album, setAlbum] = useState(null);
  const [configGenerated, setConfigGenerated] = useState(false);
  const [configUseMetadata, setConfigUseMetadata] = useState(false);
  const [configUseFileNames, setConfigUseFileNames] = useState(false);
  const [configReplaceTitle, setConfigReplaceTitle] = useState(false);
  const [configSingleWord, setConfigSingleWord] = useState(false);
  const [configTitleCaseTitle, setConfigTitleCaseTitle] = useState(false);
  const [configArtistic, setConfigArtistic] = useState(false);
  const [configLanguage, setConfigLanguage] = useState(false);
  const [configLanguageValue, setConfigLanguageValue] = useState("English");
  const [configMaxKeywords, setConfigMaxKeywords] = useState(false);
  const [configMinKeywords, setConfigMinKeywords] = useState(false);
  const [configMaxTitle, setConfigMaxTitle] = useState(false);
  const [configMinTitle, setConfigMinTitle] = useState(false);
  const [configMaxDescription, setConfigMaxDescription] = useState(false);
  const [configMinDescription, setConfigMinDescription] = useState(false);
  const [configRequiredKeywords, setConfigRequiredKeywords] = useState(false);
  const [configExcludedKeywords, setConfigExcludedKeywords] = useState(false);
  const [configCustomContext, setConfigCustomContext] = useState(false);
  const [configProhibitedChars, setConfigProhibitedChars] = useState(false);
  const [configMaxKeywordsValue, setConfigMaxKeywordsValue] = useState(false);
  const [configMinKeywordsValue, setConfigMinKeywordsValue] = useState(false);
  const [configMaxTitleValue, setConfigMaxTitleValue] = useState(false);
  const [configMinTitleValue, setConfigMinTitleValue] = useState(false);
  const [configMaxDescriptionValue, setConfigMaxDescriptionValue] =
    useState(false);
  const [configMinDescriptionValue, setConfigMinDescriptionValue] =
    useState(false);
  const [configReqKeywordsValue, setConfigReqKeywordsValue] = useState("");
  const [configExcludedKeywordsValue, setConfigExcludedKeywordsValue] =
    useState("");
  const [configCustomContextValue, setConfigCustomContextValue] = useState("");
  const [configProhibitedCharsValue, setConfigProhibitedCharsValue] =
    useState("");
  const [configAlbumName, setConfigAlbumName] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);
  const [uploadingMessage, setUploadingMessage] = useState(
    t("upload.pleaseWait")
  );
  const [uploadTimestamp, setUploadTimestamp] = useState(null);
  const [uploadProcessing, setUploadProcessing] = useState(false);
  const [expandedGeneral, setExpandedGeneral] = useState(false);
  const [expandedTitle, setExpandedTitle] = useState(false);
  const [expandedKeywords, setExpandedKeywords] = useState(false);

  const navigate = useNavigate();
  const session = useContext(SessionContext);
  const navbar = useContext(NavbarContext);

  const errorHandling = (obj) => {
    resetUpload();
    setUploadError(obj);
  };

  const resetUpload = () => {
    setInputFiles(null);
    setUploaded(0);
    setQueue(false);
    setUploadError(null);
  };

  const grabVideoScreenshot = async (video) => {
    return new Promise((resolve, reject) => {
      const videoElement = document.createElement("video");
      videoElement.preload = "metadata"; // Load only metadata initially
      const objectURL = URL.createObjectURL(video); // Create the Blob URL
      videoElement.src = objectURL;
      let isCaptureInitiated = false; // Prevent multiple captures

      const cleanup = () => {
        URL.revokeObjectURL(objectURL); // Free up the object URL
        videoElement.onloadedmetadata = null;
        videoElement.oncanplaythrough = null;
        videoElement.onseeked = null;
        videoElement.onerror = null;
        videoElement.removeAttribute("src"); // Ensure the source is cleared
        videoElement.load(); // Load with the empty source to reset the element
      };

      videoElement.onloadedmetadata = () => {
        videoElement.oncanplaythrough = () => {
          if (isCaptureInitiated) return; // Prevent multiple captures
          isCaptureInitiated = true; // Mark capture as initiated
          // Calculate the time for the screenshot (10% of the video's duration)
          const captureTime = videoElement.duration * 0.1;
          videoElement.currentTime = captureTime;
        };
      };

      videoElement.onseeked = () => {
        if (videoElement.readyState >= 2) {
          const scaleFactor = 0.5;
          const canvas = document.createElement("canvas");
          canvas.width = videoElement.videoWidth * scaleFactor;
          canvas.height = videoElement.videoHeight * scaleFactor;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(
            (blob) => {
              if (blob) {
                resolve(new File([blob], video.name, { type: "image/jpeg" }));
              } else {
                reject(new Error("Failed to capture video frame."));
              }
              cleanup();
            },
            "image/jpeg",
            0.5
          );
        } else {
          reject(new Error("Video is not ready for capturing a screenshot."));
          cleanup();
        }
      };

      videoElement.onerror = (e) => {
        reject(e);
        cleanup();
      };

      videoElement.load(); // Start loading the video's metadata
    });
  };

  const grabVideoScreenshotWithTimeout = async (video) => {
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(
        () => reject(new Error("Screenshot capture timed out")),
        6 * 60 * 1000
      )
    );

    try {
      const videoScreenshot = await Promise.race([
        grabVideoScreenshot(video),
        timeoutPromise,
      ]);
      return videoScreenshot; // Successfully captured screenshot
    } catch (error) {
      throw error; // Rethrow or handle error as needed
    }
  };

  const grabSvgScreenshot = async (svg) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = 500;
        canvas.height = 500;
        const ctx = canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(new File([blob], svg.name, { type: "image/jpeg" }));
            } else {
              reject(new Error("Failed to capture SVG screenshot."));
            }
          },
          "image/jpeg",
          0.5
        );
      };
      img.onerror = (e) => reject(e);
      img.src = URL.createObjectURL(svg);
    });
  };

  function sanitizeString(str) {
    if (typeof str !== "string") return undefined;

    return str.replace(/[^a-zA-Z0-9 .,!?@#&()-]/g, "");
  }

  async function handleChange(fileList) {
    try {
      if (!session?.user) {
        navbar.setSelectedNavItem("login");
        navbar.setOpenNavModal(true);
        return;
      }

      setUploadProcessing(true);

      if (
        fileList?.length >
        Number(
          session?.subStatus?.[session?.subStatus?.length - 1]?.plan?.metadata
            ?.batch
        )
      ) {
        errorHandling({ message: t("upload.batchError") });
        setUploadProcessing(false);
        return;
      }

      if (
        fileList?.length >
        Number(
          session?.subStatus?.[session?.subStatus?.length - 1]?.plan?.metadata
            ?.credits
        ) +
          (session?.bundleCreditsAllowed || 0) -
          (session?.planCreditsUsed || 0) -
          (session?.bundleCreditsUsed || 0)
      ) {
        errorHandling({ message: t("upload.creditError") });
        setUploadProcessing(false);
        return;
      }

      const maxPhotoSizeMB = session?.subStatus?.[
        session?.subStatus?.length - 1
      ]?.active
        ? Number(
            session?.subStatus?.[session?.subStatus?.length - 1]?.plan?.metadata
              ?.size
          )
        : 40;

      const maxVideoSizeMB = session?.subStatus?.[
        session?.subStatus?.length - 1
      ]?.active
        ? Number(
            session?.subStatus?.[session?.subStatus?.length - 1]?.plan?.metadata
              ?.vsize
          )
        : 500;

      let fileListCopy = [...fileList];
      let fileListMetadata = [];
      for (let i = 0; i < fileListCopy.length; i++) {
        if (configUseMetadata) {
          if (fileListCopy[i].type.includes("video")) {
            fileListMetadata.push({});
          } else {
            try {
              const metadata = await exifr.parse(fileListCopy[i], {
                iptc: true,
                xmp: true,
                exif: true,
              });
              fileListMetadata.push({
                name: fileListCopy[i].name,
                title: sanitizeString(
                  metadata.Headline || metadata.title?.value || metadata.XPTitle
                ),
                description: sanitizeString(
                  metadata.Caption ||
                    metadata.description?.value ||
                    metadata.ImageDescription
                ),
                keywords: sanitizeString(
                  metadata.Keywords
                    ? metadata.Keywords.join(", ")
                    : metadata.subject
                    ? metadata.subject.join(", ")
                    : metadata.XPKeywords
                ),
              });
            } catch (error) {
              fileListMetadata.push({});
            }
          }
        }

        if (fileListCopy[i].type.includes("video")) {
          if (fileListCopy[i].size / 1024 / 1024 > maxVideoSizeMB) {
            errorHandling({
              message: t("upload.videoSizeError", {
                size: maxVideoSizeMB,
                index: i + 1,
                name: fileListCopy[i].name,
              }),
            });
            setUploadProcessing(false);
            return;
          }
          try {
            const video = fileListCopy[i];
            const videoScreenshot = await grabVideoScreenshotWithTimeout(video);
            fileListCopy[i] = videoScreenshot;
          } catch (error) {
            errorHandling({
              message: t("upload.screenshotError", {
                index: i + 1,
                name: fileListCopy[i].name,
              }),
            });
            setUploadProcessing(false);
            return;
          }
        } else {
          if (fileListCopy[i].size / 1024 / 1024 > maxPhotoSizeMB) {
            errorHandling({
              message: t("upload.photoSizeError", {
                size: maxPhotoSizeMB,
                index: i + 1,
                name: fileListCopy[i].name,
              }),
            });
            setUploadProcessing(false);
            return;
          }
          if (fileListCopy[i].type === "image/svg+xml") {
            try {
              const svg = fileListCopy[i];
              const svgScreenshot = await grabSvgScreenshot(svg);
              fileListCopy[i] = svgScreenshot;
            } catch (error) {
              errorHandling({
                message: t("upload.screenshotError", {
                  index: i + 1,
                  name: fileListCopy[i].name,
                }),
              });
              setUploadProcessing(false);
              return;
            }
          }
        }
      }

      setInputFiles(fileListCopy);
      setUploadError(null);
      const files = fileListCopy;
      setQueue(files.length);

      const resizeConfig = {
        quality: 0.6,
        maxWidth: 700,
        autoRotate: true,
      };
      const formData = new FormData();
      let filesArray = Object.values(files);
      for (const f of filesArray) {
        try {
          const resizedImage = await readAndCompressImage(f, resizeConfig);
          formData.append("file", resizedImage, f.name);
        } catch (error) {
          const resizeImage = (file, config) => {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.src = URL.createObjectURL(file);
              img.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                const scaleFactor = config.maxWidth / img.width;
                canvas.width = config.maxWidth;
                canvas.height = img.height * scaleFactor;

                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                canvas.toBlob(
                  (blob) => {
                    resolve(blob);
                  },
                  "image/jpeg",
                  config.quality
                );
              };
              img.onerror = (error) => reject(error);
            });
          };
          const resizedImage = await resizeImage(f, resizeConfig);
          formData.append("file", resizedImage, f.name);
        }
      }

      let maxKeywordsValue = false;

      if (configMaxKeywords && typeof configMaxKeywordsValue === "number") {
        maxKeywordsValue = configMaxKeywordsValue;
        if (maxKeywordsValue > maximumMaxKeywords) {
          maxKeywordsValue = maximumMaxKeywords;
        } else if (maxKeywordsValue < minimumMaxKeywords) {
          maxKeywordsValue = minimumMaxKeywords;
        }
      }

      let minKeywordsValue = false;

      if (configMinKeywords && typeof configMinKeywordsValue === "number") {
        minKeywordsValue = configMinKeywordsValue;
        if (minKeywordsValue > maximumMinKeywords) {
          minKeywordsValue = maximumMinKeywords;
        } else if (minKeywordsValue < minimumMinKeywords) {
          minKeywordsValue = minimumMinKeywords;
        }
        maxKeywordsValue = false;
      }

      let maxTitleValue = false;

      if (configMaxTitle && typeof configMaxTitleValue === "number") {
        maxTitleValue = configMaxTitleValue;
        if (maxTitleValue > maximumMaxTitle) {
          maxTitleValue = maximumMaxTitle;
        } else if (maxTitleValue < minimumMaxTitle) {
          maxTitleValue = minimumMaxTitle;
        }
      }

      let minTitleValue = false;

      if (configMinTitle && typeof configMinTitleValue === "number") {
        minTitleValue = configMinTitleValue;
        if (minTitleValue > maximumMinTitle) {
          minTitleValue = maximumMinTitle;
        } else if (minTitleValue < minimumMinTitle) {
          minTitleValue = minimumMinTitle;
        }
        maxTitleValue = false;
      }

      if (configReplaceTitle) {
        maxTitleValue = false;
      }

      let maxDescriptionValue = false;

      if (
        configMaxDescription &&
        typeof configMaxDescriptionValue === "number"
      ) {
        maxDescriptionValue = configMaxDescriptionValue;
        if (maxDescriptionValue > maximumMaxDescription) {
          maxDescriptionValue = maximumMaxDescription;
        } else if (maxDescriptionValue < minimumMaxDescription) {
          maxDescriptionValue = minimumMaxDescription;
        }
      }

      let minDescriptionValue = false;

      if (
        configMinDescription &&
        typeof configMinDescriptionValue === "number"
      ) {
        minDescriptionValue = configMinDescriptionValue;
        if (minDescriptionValue > maximumMinDescription) {
          minDescriptionValue = maximumMinDescription;
        } else if (minDescriptionValue < minimumMinDescription) {
          minDescriptionValue = minimumMinDescription;
        }
        maxDescriptionValue = false;
      }

      const settings = {
        name: configAlbumName.trim() || null,
        language: configLanguage ? configLanguageValue : null,
        generated: configGenerated,
        artistic: configArtistic,
        singleWordKeywords: configSingleWord,
        useMetadata: configUseMetadata,
        useFileNames: configUseFileNames,
        replaceTitle: configReplaceTitle,
        titleCaseTitle: configTitleCaseTitle,
        maxKeywords: maxKeywordsValue,
        minKeywords: minKeywordsValue,
        maxTitle: maxTitleValue,
        minTitle: minTitleValue,
        maxDescription: maxDescriptionValue,
        minDescription: minDescriptionValue,
        requiredKeywords: configRequiredKeywords
          ? configReqKeywordsValue
          : configRequiredKeywords,
        excludedKeywords: configExcludedKeywords
          ? configExcludedKeywordsValue
          : configExcludedKeywords,
        customContext: configCustomContext
          ? configCustomContextValue
          : configCustomContext,
        prohibitedChars: configProhibitedChars
          ? configProhibitedCharsValue
          : configProhibitedChars,
        languagePreferred: `${
          navigator.language || navigator.userLanguage
        } | ${localStorage.getItem("i18nextLng")}`,
      };
      formData.append("ownerId", session.user.id);
      formData.append("settings", JSON.stringify(settings));
      if (configUseMetadata) {
        formData.append("metadata", JSON.stringify(fileListMetadata));
      }

      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/upload`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${supabaseSession.access_token}`,
          },
          body: formData,
        }
      );
      const json = await response.json();

      const { error } = json;
      if (error) {
        errorHandling({ message: error });
      } else {
        const numFilesUploaded = fileListCopy.length;
        startTimeout(numFilesUploaded);
      }
      setUploadProcessing(false);
    } catch (error) {
      errorHandling({ message: t("upload.tryAgain") });
      setUploadProcessing(false);
      return;
    }
  }

  const handleEditMaxKeywords = (e) => {
    e.preventDefault();
    let input = e.target.value;
    const cleanedInput = input.replace(/[^0-9]/g, "");
    if (cleanedInput === "") {
      setConfigMaxKeywordsValue("");
      localStorage.removeItem("maxKeywords");
    } else {
      const parsedInput = parseInt(cleanedInput, 10);
      if (!isNaN(parsedInput)) {
        setConfigMaxKeywordsValue(parsedInput);
        localStorage.setItem("maxKeywords", parsedInput);
      }
    }
  };

  const handleEditMinKeywords = (e) => {
    e.preventDefault();
    let input = e.target.value;
    const cleanedInput = input.replace(/[^0-9]/g, "");
    if (cleanedInput === "") {
      setConfigMinKeywordsValue("");
      localStorage.removeItem("minKeywords");
    } else {
      const parsedInput = parseInt(cleanedInput, 10);
      if (!isNaN(parsedInput)) {
        setConfigMinKeywordsValue(parsedInput);
        localStorage.setItem("minKeywords", parsedInput);
      }
    }
  };

  const handleEditMaxTitle = (e) => {
    e.preventDefault();
    let input = e.target.value;
    const cleanedInput = input.replace(/[^0-9]/g, "");
    if (cleanedInput === "") {
      setConfigMaxTitleValue("");
      localStorage.removeItem("maxTitle");
    } else {
      const parsedInput = parseInt(cleanedInput, 10);
      if (!isNaN(parsedInput)) {
        setConfigMaxTitleValue(parsedInput);
        localStorage.setItem("maxTitle", parsedInput);
      }
    }
  };

  const handleEditMinTitle = (e) => {
    e.preventDefault();
    let input = e.target.value;
    const cleanedInput = input.replace(/[^0-9]/g, "");
    if (cleanedInput === "") {
      setConfigMinTitleValue("");
      localStorage.removeItem("minTitle");
    } else {
      const parsedInput = parseInt(cleanedInput, 10);
      if (!isNaN(parsedInput)) {
        setConfigMinTitleValue(parsedInput);
        localStorage.setItem("minTitle", parsedInput);
      }
    }
  };

  const handleEditMaxDescription = (e) => {
    e.preventDefault();
    let input = e.target.value;
    const cleanedInput = input.replace(/[^0-9]/g, "");
    if (cleanedInput === "") {
      setConfigMaxDescriptionValue("");
      localStorage.removeItem("maxDescription");
    } else {
      const parsedInput = parseInt(cleanedInput, 10);
      if (!isNaN(parsedInput)) {
        setConfigMaxDescriptionValue(parsedInput);
        localStorage.setItem("maxDescription", parsedInput);
      }
    }
  };

  const handleEditMinDescription = (e) => {
    e.preventDefault();
    let input = e.target.value;
    const cleanedInput = input.replace(/[^0-9]/g, "");
    if (cleanedInput === "") {
      setConfigMinDescriptionValue("");
      localStorage.removeItem("minDescription");
    } else {
      const parsedInput = parseInt(cleanedInput, 10);
      if (!isNaN(parsedInput)) {
        setConfigMinDescriptionValue(parsedInput);
        localStorage.setItem("minDescription", parsedInput);
      }
    }
  };

  const handleEditRequiredKeywords = (e) => {
    e.preventDefault();
    let input = e.target.value;
    if (input.length <= 1000) {
      const cleanedInput = input.replace(/[^a-zA-Z0-9\s-,:;.]/g, "");
      setConfigReqKeywordsValue(cleanedInput);
      localStorage.setItem("requiredKeywords", cleanedInput);
    }
  };

  const handleEditExcludedKeywords = (e) => {
    e.preventDefault();
    let input = e.target.value;
    if (input.length <= 1000) {
      const cleanedInput = input.replace(/[^a-zA-Z0-9\s-,:;.]/g, "");
      setConfigExcludedKeywordsValue(cleanedInput);
      localStorage.setItem("excludedKeywords", cleanedInput);
    }
  };

  const handleEditCustomContext = (e) => {
    e.preventDefault();
    let input = e.target.value;
    if (input.length <= 2000) {
      const cleanedInput = input.replace(/[^a-zA-Z0-9\s-,:;.]/g, "");
      setConfigCustomContextValue(cleanedInput);
      localStorage.setItem("customContext", cleanedInput);
    }
  };

  const handleEditProhibitedChars = (e) => {
    const removeUnwantedChars = (str) => {
      const allowedChars =
        "~!@#$%^&*()_+`-={}|[]\\;':\",./<>?áéíóúüñÁÉÍÓÚÜÑàèìòùÀÈÌÒÙâêîôûÂÊÎÔÛãõÃÕçÇäëïöüÄËÏÖÜâêîôûÂÊÎÔÛāēīōūĀĒĪŌŪăĕĭŏŭĂĔĬŎŬąęįųĄĘĮŲåøÅØąęįųĄĘĮŲ：’、。";
      const seenChars = new Set();
      return str
        .split("")
        .filter(
          (char) =>
            allowedChars.includes(char) &&
            !seenChars.has(char) &&
            seenChars.add(char)
        )
        .join("");
    };
    e.preventDefault();
    let input = e.target.value;
    if (input.length <= 1000) {
      const cleanedInput = removeUnwantedChars(input);
      setConfigProhibitedCharsValue(cleanedInput);
      localStorage.setItem("prohibitedChars", cleanedInput);
    }
  };

  const handleEditLanguage = (e) => {
    e.preventDefault();
    let input = e.target.value;
    setConfigLanguageValue(input);
    localStorage.setItem("language", input);
  };

  const handleEditAlbumTitle = (e) => {
    e.preventDefault();
    let input = e.target.value;
    if (input.length < 100) {
      const cleanedInput = input.replace(/[^a-zA-Z0-9\s-]/g, "");
      setConfigAlbumName(cleanedInput);
      if (cleanedInput === "") {
        localStorage.removeItem("albumName");
      } else {
        localStorage.setItem("albumName", cleanedInput);
      }
    }
  };

  function startTimeout(numFiles) {
    const longUploadExpected = false;
    const expectedDuration = numFiles * 1000;
    const timeoutDuration = 30000 + expectedDuration * 5;
    const startTime = Date.now();

    const updateMessageInterval = setInterval(() => {
      if (!longUploadExpected) {
        const elapsed = Date.now() - startTime;
        const percentage = Math.min(
          (elapsed / expectedDuration) * 100,
          100
        ).toFixed(0);
        setUploadingMessage(`${percentage}%`);
      } else {
        setUploadingMessage(t("upload.longUploadMessage"));
      }
    }, 1000);

    const startedTimeoutId = setTimeout(() => {
      clearInterval(updateMessageInterval);
      if (!longUploadExpected) {
        setUploadingMessage(t("upload.timeoutMessage"));
      }
      if (session?.user?.id && uploadTimestamp) {
        const { data, error } = supabase
          .from("album")
          .select("id")
          .eq("created_by", session?.user?.id)
          .gte("created_at", uploadTimestamp);
        if (!error && data?.length > 0) {
          setAlbum(data[0].id);
        }
      }
    }, timeoutDuration);

    setTimeoutId(startedTimeoutId);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (album) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      navigate(`/result/${album}`);
    }
  }, [album, navigate, timeoutId]);

  useEffect(() => {
    resetUpload();
  }, [resetUploadState]);

  useEffect(() => {
    if (queue) {
      navbar?.setIsVisible(false);
    } else {
      navbar?.setIsVisible(true);
    }
  }, [queue, navbar]);

  useEffect(() => {
    session?.setSubChanged(true);
  }, [session]);

  useEffect(() => {
    const userId = session?.user?.id;
    if (!userId) return;

    const currTimestamp = new Date().toISOString();
    setUploadTimestamp(currTimestamp);

    const channel = supabase
      .channel("schema-db-changes")
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "album",
          filter: `created_by=eq.${userId}`,
        },
        (payload) => {
          const album = payload.new;
          if (album.created_by === userId && album.created_at > currTimestamp) {
            setAlbum(album.id);
          }
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [session?.user?.id, supabase]);

  useEffect(() => {
    const isEmailAddress = (str) => {
      const pattern = new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]+$");
      return pattern.test(str);
    };

    const profitWellStarted = session?.profitWellStarted;
    const userEmail = session?.user?.email;

    if (
      !profitWellStarted &&
      userEmail &&
      isEmailAddress(userEmail) &&
      window.profitwell
    ) {
      window.profitwell("start", { user_email: `${userEmail}` });
      session?.setProfitWellStarted(true);
    }
  }, [session]);

  useEffect(() => {
    const prevConfigRequiredKeywords = localStorage.getItem("requiredKeywords");
    if (prevConfigRequiredKeywords) {
      setConfigRequiredKeywords(true);
      setConfigReqKeywordsValue(prevConfigRequiredKeywords);
    }
    const prevConfigExcludedKeywords = localStorage.getItem("excludedKeywords");
    if (prevConfigExcludedKeywords) {
      setConfigExcludedKeywords(true);
      setConfigExcludedKeywordsValue(prevConfigExcludedKeywords);
    }
    const prevConfigCustomContext = localStorage.getItem("customContext");
    if (prevConfigCustomContext) {
      setConfigCustomContext(true);
      setConfigCustomContextValue(prevConfigCustomContext);
    }
    const prevConfigProhibitedChars = localStorage.getItem("prohibitedChars");
    if (prevConfigProhibitedChars) {
      setConfigProhibitedChars(true);
      setConfigProhibitedCharsValue(prevConfigProhibitedChars);
    }
    const prevMaxKeywords = localStorage.getItem("maxKeywords");
    if (prevMaxKeywords) {
      const prevMaxKeywordsValue = parseInt(prevMaxKeywords, 10);
      if (!isNaN(prevMaxKeywordsValue)) {
        setConfigMaxKeywords(true);
        setConfigMaxKeywordsValue(prevMaxKeywordsValue);
      }
    }
    const prevMinKeywords = localStorage.getItem("minKeywords");
    if (prevMinKeywords) {
      const prevMinKeywordsValue = parseInt(prevMinKeywords, 10);
      if (!isNaN(prevMinKeywordsValue)) {
        setConfigMinKeywords(true);
        setConfigMinKeywordsValue(prevMinKeywordsValue);
      }
    }
    const prevMaxTitle = localStorage.getItem("maxTitle");
    if (prevMaxTitle) {
      const prevMaxTitleValue = parseInt(prevMaxTitle, 10);
      if (!isNaN(prevMaxTitleValue)) {
        setConfigMaxTitle(true);
        setConfigMaxTitleValue(prevMaxTitleValue);
      }
    }
    const prevMinTitle = localStorage.getItem("minTitle");
    if (prevMinTitle) {
      const prevMinTitleValue = parseInt(prevMinTitle, 10);
      if (!isNaN(prevMinTitleValue)) {
        setConfigMinTitle(true);
        setConfigMinTitleValue(prevMinTitleValue);
      }
    }
    const prevMaxDescription = localStorage.getItem("maxDescription");
    if (prevMaxDescription) {
      const prevMaxDescriptionValue = parseInt(prevMaxDescription, 10);
      if (!isNaN(prevMaxDescriptionValue)) {
        setConfigMaxDescription(true);
        setConfigMaxDescriptionValue(prevMaxDescriptionValue);
      }
    }
    const prevMinDescription = localStorage.getItem("minDescription");
    if (prevMinDescription) {
      const prevMinDescriptionValue = parseInt(prevMinDescription, 10);
      if (!isNaN(prevMinDescriptionValue)) {
        setConfigMinDescription(true);
        setConfigMinDescriptionValue(prevMinDescriptionValue);
      }
    }
    const prevConfigSingleWord = localStorage.getItem("configSingleWord");
    if (prevConfigSingleWord) {
      setConfigSingleWord(true);
    }
    const prevConfigArtistic = localStorage.getItem("configArtistic");
    if (prevConfigArtistic) {
      setConfigArtistic(true);
    }
    const prevConfigGenerated = localStorage.getItem("configGenerated");
    if (prevConfigGenerated) {
      setConfigGenerated(true);
    }
    const prevConfigAlbumName = localStorage.getItem("albumName");
    if (prevConfigAlbumName) {
      setConfigAlbumName(prevConfigAlbumName);
    }
    const prevConfigLanguage = localStorage.getItem("language");
    if (prevConfigLanguage) {
      setConfigLanguage(true);
      setConfigLanguageValue(prevConfigLanguage);
    }
    const prevConfigUseMetadata = localStorage.getItem("configUseMetadata");
    if (prevConfigUseMetadata) {
      setConfigUseMetadata(true);
    }
    const prevConfigUseFileNames = localStorage.getItem("configUseFileNames");
    if (prevConfigUseFileNames) {
      setConfigUseFileNames(true);
    }
    const prevConfigReplaceTitle = localStorage.getItem("configReplaceTitle");
    if (prevConfigReplaceTitle) {
      setConfigReplaceTitle(true);
    }
    const prevConfigTitleCaseTitle = localStorage.getItem(
      "configTitleCaseTitle"
    );
    if (prevConfigTitleCaseTitle) {
      setConfigTitleCaseTitle(true);
    }
  }, []);

  useEffect(() => {
    const shouldExpandGeneral =
      configUseMetadata ||
      configUseFileNames ||
      configCustomContext ||
      configProhibitedChars ||
      configLanguage ||
      configGenerated ||
      configArtistic;
    if (shouldExpandGeneral) {
      setExpandedGeneral(true);
    }

    const shouldExpandTitle =
      configReplaceTitle ||
      configMaxDescription ||
      configMinDescription ||
      configTitleCaseTitle ||
      configMaxTitle ||
      configMinTitle;
    if (shouldExpandTitle) {
      setExpandedTitle(true);
    }

    const shouldExpandKeywords =
      configMaxKeywords ||
      configMinKeywords ||
      configSingleWord ||
      configRequiredKeywords ||
      configExcludedKeywords;
    if (shouldExpandKeywords) {
      setExpandedKeywords(true);
    }
  }, [
    configUseMetadata,
    configUseFileNames,
    configCustomContext,
    configProhibitedChars,
    configLanguage,
    configGenerated,
    configArtistic,
    configReplaceTitle,
    configMaxDescription,
    configMinDescription,
    configTitleCaseTitle,
    configMaxTitle,
    configMinTitle,
    configMaxKeywords,
    configMinKeywords,
    configSingleWord,
    configRequiredKeywords,
    configExcludedKeywords,
  ]);

  return session?.user ? (
    !uploadError && !queue ? (
      <Fragment>
        <LanguageSelector />
        <Box
          sx={{
            background: "linear-gradient(to right, #d3cce3, #e9e4f0)",
            backgroundSize: "contain",
            backgroundPosition: "right",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            width: "100%",
            minHeight: "100vh",
            overflowX: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              justifyContent: "flex-start",
              alignItems: "center",
              mt: 10,
              mb: {
                xs: 120,
                md: 0,
              },
              padding: {
                xs: "20% 10%",
                sm: "6rem 26%",
                md: "5% 26%",
                lg: "5% 26%",
                xl: "2% 26%",
              },
              maxWidth: { xs: "90vw", md: "200vw" },
              aspectRatio: { xs: "9 / 16", md: "16 / 9" },
            }}
          >
            <Typography
              variant="h3"
              color="initial"
              fontWeight="bold"
              sx={{ align: "left", color: "#000" }}
            >
              {t("upload.upload")}
            </Typography>
            {session?.subStatus?.[session?.subStatus?.length - 1]?.plan
              ?.metadata !== undefined &&
              session?.bundleCreditsAllowed !== undefined &&
              session?.planCreditsUsed !== undefined &&
              session?.bundleCreditsUsed !== undefined && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignitems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{ display: "flex", gap: "0.5rem", alignSelf: "center" }}
                  >
                    <CheckRoundedIcon color="success" />
                    <Typography
                      variant="body2"
                      color="initial"
                      sx={{
                        align: "left",
                        color: "rgba(0,0,0,0.6)",
                        fontSize: "0.9rem",
                      }}
                    >
                      {t("upload.maxBatch")}{" "}
                      <span style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                        {addCommas(
                          session?.subStatus?.[session?.subStatus?.length - 1]
                            ?.plan?.metadata?.batch || 0
                        )}{" "}
                        {t("upload.photos")}
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", gap: "0.5rem", alignSelf: "center" }}
                  >
                    <CheckRoundedIcon color="success" />
                    <Typography
                      variant="body2"
                      color="initial"
                      sx={{
                        align: "left",
                        color: "rgba(0,0,0,0.6)",
                        fontSize: "0.9rem",
                      }}
                    >
                      {t("upload.maxSize")}{" "}
                      <span style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                        {addCommas(
                          session?.subStatus?.[session?.subStatus?.length - 1]
                            ?.plan?.metadata?.size
                        ) || 40}
                        MB
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", gap: "0.5rem", alignSelf: "center" }}
                  >
                    <CheckRoundedIcon color="success" />
                    <Typography
                      variant="body2"
                      color="initial"
                      sx={{
                        align: "left",
                        color: "rgba(0,0,0,0.6)",
                        fontSize: "0.9rem",
                      }}
                    >
                      {t("upload.maxVideoSize")}{" "}
                      <span style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                        {session?.subStatus?.[session?.subStatus?.length - 1]
                          ?.plan?.metadata?.vsize
                          ? addCommas(
                              session?.subStatus?.[
                                session?.subStatus?.length - 1
                              ]?.plan?.metadata?.vsize
                            )
                          : 500}
                        MB
                      </span>
                    </Typography>
                  </Box>
                </Box>
              )}

            {session?.user && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "center",
                  alignItems: "stretch",
                  gap: { xs: "1rem", md: "1rem" },
                  maxWidth: "100%",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#121216",
                    maxWidth: "100%",
                    padding: "2rem",
                    boxSizing: "border-box",
                    borderRadius: "2rem",
                    width: "100%",
                    minWidth: { xs: "100%", md: "70%" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "1rem",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      mb: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          alignItems: "center",
                        }}
                      >
                        <SettingsSuggestIcon htmlColor="white" />
                        <Typography
                          variant="h5"
                          color="initial"
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                          }}
                        >
                          {t("upload.settings")}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        gap: "1rem",
                        justifyContent: "space-between",
                        width: "100%",
                        position: "relative",
                        height: "4rem",
                      }}
                    >
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={configAlbumName}
                        variant="outlined"
                        placeholder={t("upload.albumName")}
                        InputProps={{
                          style: {
                            color: "#dcdcdc",
                            fontSize: "1rem",
                            backgroundColor: "transparent",
                            border: "none",
                            padding: 0,
                            borderRadius: "1.5rem",
                            height: "100%",
                            fontWeight: "bold",
                          },
                        }}
                        sx={{
                          flexGrow: 1,
                          fieldset: {
                            borderColor: "hsla(0,0%,100%,.1)",
                            transition: "all .2s ease-out",
                          },
                          width: "100%",
                        }}
                        onChange={(e) => handleEditAlbumTitle(e)}
                      />
                    </Box>
                  </Box>

                  <Accordion
                    sx={{ mb: "1rem" }}
                    expanded={expandedGeneral}
                    onChange={() => setExpandedGeneral((prev) => !prev)}
                  >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {t("upload.generalSettings")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ flexDirection: "column", gap: "1rem" }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: "1.5rem",
                          }}
                        >
                          <Tooltip
                            arrow
                            placement="right"
                            title={t("upload.useMetadataHint")}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#dcdcdc", fontWeight: "bold" }}
                            >
                              {t("upload.useMetadata")}{" "}
                              <InfoIcon sx={{ fontSize: "20px" }}></InfoIcon>
                            </Typography>
                          </Tooltip>

                          <Switch
                            color="secondary"
                            value={configUseMetadata}
                            checked={configUseMetadata}
                            onChange={() => {
                              const newValue = !configUseMetadata;
                              if (newValue) {
                                localStorage.setItem("configUseMetadata", true);
                              } else {
                                localStorage.removeItem("configUseMetadata");
                              }
                              setConfigUseMetadata(newValue);
                            }}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: "1.5rem",
                          }}
                        >
                          <Tooltip
                            arrow
                            placement="right"
                            title={t("upload.useFileNamesHint")}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#dcdcdc", fontWeight: "bold" }}
                            >
                              {t("upload.useFileNames")}{" "}
                              <InfoIcon sx={{ fontSize: "20px" }}></InfoIcon>
                            </Typography>
                          </Tooltip>

                          <Switch
                            color="secondary"
                            value={configUseFileNames}
                            checked={configUseFileNames}
                            onChange={() => {
                              const newValue = !configUseFileNames;
                              if (newValue) {
                                localStorage.setItem(
                                  "configUseFileNames",
                                  true
                                );
                              } else {
                                localStorage.removeItem("configUseFileNames");
                              }
                              setConfigUseFileNames(newValue);
                            }}
                          />
                        </Box>

                        <Box sx={{ marginBottom: "0.6rem" }}>
                          <Accordion
                            sx={{
                              all: "unset",
                              width: "100%",
                            }}
                            disableGutters
                            elevation={0}
                            expanded={configCustomContext}
                          >
                            <AccordionSummary
                              sx={{ all: "unset", width: "100%" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Tooltip
                                  arrow
                                  placement="right"
                                  title={t("upload.customContextHint")}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: "#dcdcdc",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {t("upload.customContext")}{" "}
                                    <InfoIcon
                                      sx={{ fontSize: "20px" }}
                                    ></InfoIcon>
                                  </Typography>
                                </Tooltip>
                                <Switch
                                  color="secondary"
                                  value={configCustomContext}
                                  checked={configCustomContext}
                                  onChange={() => {
                                    const newValue = !configCustomContext;
                                    if (!newValue) {
                                      localStorage.removeItem("customContext");
                                    }
                                    setConfigCustomContext(newValue);
                                  }}
                                />
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ all: "unset" }}>
                              <TextField
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={configCustomContextValue}
                                variant="outlined"
                                placeholder={t(
                                  "upload.customContextPlaceholder"
                                )}
                                InputProps={{
                                  style: {
                                    color: "#dcdcdc",
                                    fontSize: "1rem",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    padding: 0,
                                    borderRadius: "1.5rem",
                                    height: "100%",
                                    fontWeight: "bold",
                                  },
                                }}
                                sx={{
                                  flexGrow: 1,
                                  fieldset: {
                                    borderColor: "hsla(0,0%,100%,.1)",
                                    transition: "all .2s ease-out",
                                  },
                                  width: "100%",
                                }}
                                onChange={(e) => handleEditCustomContext(e)}
                              />
                            </AccordionDetails>
                          </Accordion>
                        </Box>

                        <Box sx={{ marginBottom: "0.6rem" }}>
                          <Accordion
                            sx={{
                              all: "unset",
                              width: "100%",
                            }}
                            disableGutters
                            elevation={0}
                            expanded={configProhibitedChars}
                          >
                            <AccordionSummary
                              sx={{ all: "unset", width: "100%" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Tooltip
                                  arrow
                                  placement="right"
                                  title={t("upload.prohibitedCharsHint")}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: "#dcdcdc",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {t("upload.prohibitedChars")}{" "}
                                    <InfoIcon
                                      sx={{ fontSize: "20px" }}
                                    ></InfoIcon>
                                  </Typography>
                                </Tooltip>
                                <Switch
                                  color="secondary"
                                  value={configProhibitedChars}
                                  checked={configProhibitedChars}
                                  onChange={() => {
                                    const newValue = !configProhibitedChars;
                                    if (!newValue) {
                                      localStorage.removeItem(
                                        "prohibitedChars"
                                      );
                                    }
                                    setConfigProhibitedChars(newValue);
                                  }}
                                />
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ all: "unset" }}>
                              <TextField
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={configProhibitedCharsValue}
                                variant="outlined"
                                placeholder={`,-'"`}
                                InputProps={{
                                  style: {
                                    color: "#dcdcdc",
                                    fontSize: "1rem",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    padding: 0,
                                    borderRadius: "1.5rem",
                                    height: "100%",
                                    fontWeight: "bold",
                                  },
                                }}
                                sx={{
                                  flexGrow: 1,
                                  fieldset: {
                                    borderColor: "hsla(0,0%,100%,.1)",
                                    transition: "all .2s ease-out",
                                  },
                                  width: "100%",
                                }}
                                onChange={(e) => handleEditProhibitedChars(e)}
                              />
                            </AccordionDetails>
                          </Accordion>
                        </Box>

                        <Box sx={{ marginBottom: "0.6rem" }}>
                          <Accordion
                            sx={{
                              all: "unset",
                              width: "100%",
                            }}
                            disableGutters
                            elevation={0}
                            expanded={configLanguage}
                          >
                            <AccordionSummary
                              sx={{ all: "unset", width: "100%" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Tooltip
                                  arrow
                                  placement="right"
                                  title={t("upload.outputLanguageHint")}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: "#dcdcdc",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {t("upload.outputLanguage")}{" "}
                                    <InfoIcon
                                      sx={{ fontSize: "20px" }}
                                    ></InfoIcon>
                                  </Typography>
                                </Tooltip>

                                <Switch
                                  color="secondary"
                                  value={configLanguage}
                                  checked={configLanguage}
                                  onChange={() => {
                                    const newValue = !configLanguage;
                                    if (!newValue) {
                                      localStorage.removeItem("language");
                                    }
                                    setConfigLanguage(newValue);
                                  }}
                                />
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ all: "unset" }}>
                              <Select
                                value={configLanguageValue}
                                onChange={(e) => handleEditLanguage(e)}
                                sx={{
                                  flexGrow: 1,
                                  fieldset: {
                                    borderColor: "hsla(0,0%,100%,.1)",
                                    transition: "all .2s ease-out",
                                  },
                                  width: "100%",
                                }}
                              >
                                <MenuItem value={"English"}>
                                  {t("upload.english")}
                                </MenuItem>
                                <MenuItem value={"Spanish"}>
                                  {t("upload.spanish")}
                                </MenuItem>
                                <MenuItem value={"French"}>
                                  {t("upload.french")}
                                </MenuItem>
                                <MenuItem value={"Italian"}>
                                  {t("upload.italian")}
                                </MenuItem>
                                <MenuItem value={"Portuguese"}>
                                  {t("upload.portuguese")}
                                </MenuItem>
                                <MenuItem value={"German"}>
                                  {t("upload.german")}
                                </MenuItem>
                                <MenuItem value={"Polish"}>
                                  {t("upload.polish")}
                                </MenuItem>
                                <MenuItem value={"Russian"}>
                                  {t("upload.russian")}
                                </MenuItem>
                                <MenuItem value={"Ukrainian"}>
                                  {t("upload.ukrainian")}
                                </MenuItem>
                                <MenuItem value={"Hindi"}>
                                  {t("upload.hindi")}
                                </MenuItem>
                                <MenuItem value={"Indonesian"}>
                                  {t("upload.indonesian")}
                                </MenuItem>
                                <MenuItem value={"Japanese"}>
                                  {t("upload.japanese")}
                                </MenuItem>
                                <MenuItem value={"Korean"}>
                                  {t("upload.korean")}
                                </MenuItem>
                                <MenuItem value={"Chinese"}>
                                  {t("upload.chinese")}
                                </MenuItem>
                                <MenuItem value={"Hebrew"}>
                                  {t("upload.hebrew")}
                                </MenuItem>
                                <MenuItem value={"Arabic"}>
                                  {t("upload.arabic")}
                                </MenuItem>
                              </Select>
                            </AccordionDetails>
                          </Accordion>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: "1.5rem",
                          }}
                        >
                          <Tooltip
                            arrow
                            placement="right"
                            title={t("upload.makeArtisticHint")}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#dcdcdc", fontWeight: "bold" }}
                            >
                              {t("upload.makeArtistic")}{" "}
                              <InfoIcon sx={{ fontSize: "20px" }}></InfoIcon>
                            </Typography>
                          </Tooltip>

                          <Switch
                            color="secondary"
                            value={configArtistic}
                            checked={configArtistic}
                            onChange={() => {
                              const newValue = !configArtistic;
                              if (newValue) {
                                localStorage.setItem("configArtistic", true);
                              } else {
                                localStorage.removeItem("configArtistic");
                              }
                              setConfigArtistic(newValue);
                            }}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: "0.6rem",
                          }}
                        >
                          <Tooltip
                            arrow
                            placement="right"
                            title={t("upload.aiGeneratedHint")}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#dcdcdc", fontWeight: "bold" }}
                            >
                              {t("upload.aiGenerated")}{" "}
                              <InfoIcon sx={{ fontSize: "20px" }}></InfoIcon>
                            </Typography>
                          </Tooltip>

                          <Switch
                            color="secondary"
                            value={configGenerated}
                            checked={configGenerated}
                            onChange={() => {
                              const newValue = !configGenerated;
                              if (newValue) {
                                localStorage.setItem("configGenerated", true);
                              } else {
                                localStorage.removeItem("configGenerated");
                              }
                              setConfigGenerated(newValue);
                            }}
                          />
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    sx={{ mb: "1rem" }}
                    expanded={expandedTitle}
                    onChange={() => setExpandedTitle((prev) => !prev)}
                  >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {t("upload.titleSettings")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ flexDirection: "column", gap: "1rem" }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: "1.5rem",
                          }}
                        >
                          <Tooltip
                            arrow
                            placement="right"
                            title={t("upload.titleCaseTitleHint")}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#dcdcdc", fontWeight: "bold" }}
                            >
                              {t("upload.titleCaseTitle")}{" "}
                              <InfoIcon sx={{ fontSize: "20px" }}></InfoIcon>
                            </Typography>
                          </Tooltip>

                          <Switch
                            color="secondary"
                            value={configTitleCaseTitle}
                            checked={configTitleCaseTitle}
                            onChange={() => {
                              const newValue = !configTitleCaseTitle;
                              if (newValue) {
                                localStorage.setItem(
                                  "configTitleCaseTitle",
                                  true
                                );
                                setConfigReplaceTitle(false);
                                localStorage.removeItem("configReplaceTitle");
                              } else {
                                localStorage.removeItem("configTitleCaseTitle");
                              }
                              setConfigTitleCaseTitle(newValue);
                            }}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: "1.5rem",
                          }}
                        >
                          <Tooltip
                            arrow
                            placement="right"
                            title={t("upload.replaceTitleHint")}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#dcdcdc", fontWeight: "bold" }}
                            >
                              {t("upload.replaceTitle")}{" "}
                              <InfoIcon sx={{ fontSize: "20px" }}></InfoIcon>
                            </Typography>
                          </Tooltip>

                          <Switch
                            color="secondary"
                            value={configReplaceTitle}
                            checked={configReplaceTitle}
                            onChange={() => {
                              const newValue = !configReplaceTitle;
                              if (newValue) {
                                localStorage.setItem(
                                  "configReplaceTitle",
                                  true
                                );
                                setConfigMaxTitle(false);
                                localStorage.removeItem("maxTitle");
                                setConfigMinTitle(false);
                                localStorage.removeItem("minTitle");
                                setConfigTitleCaseTitle(false);
                                localStorage.removeItem("configTitleCaseTitle");
                              } else {
                                localStorage.removeItem("configReplaceTitle");
                              }
                              setConfigReplaceTitle(newValue);
                            }}
                          />
                        </Box>

                        <Box sx={{ marginBottom: "0.6rem" }}>
                          <Accordion
                            sx={{ all: "unset", width: "100%" }}
                            disableGutters
                            elevation={0}
                            expanded={configMaxDescription}
                          >
                            <AccordionSummary
                              sx={{ all: "unset", width: "100%" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Tooltip
                                  arrow
                                  placement="right"
                                  title={t("upload.maxDescriptionHint")}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: "#dcdcdc",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {t("upload.maxDescription")}{" "}
                                    <InfoIcon
                                      sx={{ fontSize: "20px" }}
                                    ></InfoIcon>
                                  </Typography>
                                </Tooltip>

                                <Switch
                                  color="secondary"
                                  value={configMaxDescription}
                                  checked={configMaxDescription}
                                  onChange={() => {
                                    const newValue = !configMaxDescription;
                                    if (!newValue) {
                                      localStorage.removeItem("maxDescription");
                                    } else {
                                      setConfigMinDescription(false);
                                      localStorage.removeItem("minDescription");
                                    }
                                    setConfigMaxDescription(newValue);
                                  }}
                                />
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ all: "unset" }}>
                              <TextField
                                type={"number"}
                                value={configMaxDescriptionValue}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined"
                                placeholder="50 - 500"
                                InputProps={{
                                  style: {
                                    color: "#dcdcdc",
                                    fontSize: "1rem",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    padding: 0,
                                    borderRadius: "1.5rem",
                                    height: "100%",
                                    fontWeight: "bold",
                                  },
                                }}
                                sx={{
                                  flexGrow: 1,
                                  fieldset: {
                                    borderColor: "hsla(0,0%,100%,.1)",
                                    transition: "all .2s ease-out",
                                  },
                                  width: "100%",
                                }}
                                onChange={(e) => handleEditMaxDescription(e)}
                              />
                            </AccordionDetails>
                          </Accordion>
                        </Box>

                        <Box sx={{ marginBottom: "0.6rem" }}>
                          <Accordion
                            sx={{ all: "unset", width: "100%" }}
                            disableGutters
                            elevation={0}
                            expanded={configMinDescription}
                          >
                            <AccordionSummary
                              sx={{ all: "unset", width: "100%" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Tooltip
                                  arrow
                                  placement="right"
                                  title={t("upload.minDescriptionHint")}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: "#dcdcdc",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {t("upload.minDescription")}{" "}
                                    <InfoIcon
                                      sx={{ fontSize: "20px" }}
                                    ></InfoIcon>
                                  </Typography>
                                </Tooltip>

                                <Switch
                                  color="secondary"
                                  value={configMinDescription}
                                  checked={configMinDescription}
                                  onChange={() => {
                                    const newValue = !configMinDescription;
                                    if (!newValue) {
                                      localStorage.removeItem("minDescription");
                                    } else {
                                      setConfigMaxDescription(false);
                                      localStorage.removeItem("maxDescription");
                                    }
                                    setConfigMinDescription(newValue);
                                  }}
                                />
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ all: "unset" }}>
                              <TextField
                                type={"number"}
                                value={configMinDescriptionValue}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined"
                                placeholder="5 - 200"
                                InputProps={{
                                  style: {
                                    color: "#dcdcdc",
                                    fontSize: "1rem",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    padding: 0,
                                    borderRadius: "1.5rem",
                                    height: "100%",
                                    fontWeight: "bold",
                                  },
                                }}
                                sx={{
                                  flexGrow: 1,
                                  fieldset: {
                                    borderColor: "hsla(0,0%,100%,.1)",
                                    transition: "all .2s ease-out",
                                  },
                                  width: "100%",
                                }}
                                onChange={(e) => handleEditMinDescription(e)}
                              />
                            </AccordionDetails>
                          </Accordion>
                        </Box>

                        <Box sx={{ marginBottom: "0.6rem" }}>
                          <Accordion
                            sx={{ all: "unset", width: "100%" }}
                            disableGutters
                            elevation={0}
                            expanded={configMaxTitle}
                          >
                            <AccordionSummary
                              sx={{ all: "unset", width: "100%" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Tooltip
                                  arrow
                                  placement="right"
                                  title={t("upload.maxTitleHint")}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: "#dcdcdc",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {t("upload.maxTitle")}{" "}
                                    <InfoIcon
                                      sx={{ fontSize: "20px" }}
                                    ></InfoIcon>
                                  </Typography>
                                </Tooltip>

                                <Switch
                                  color="secondary"
                                  value={configMaxTitle}
                                  checked={configMaxTitle}
                                  onChange={() => {
                                    const newValue = !configMaxTitle;
                                    if (!newValue) {
                                      localStorage.removeItem("maxTitle");
                                    } else {
                                      setConfigReplaceTitle(false);
                                      localStorage.removeItem(
                                        "configReplaceTitle"
                                      );
                                      setConfigMinTitle(false);
                                      localStorage.removeItem("minTitle");
                                    }
                                    setConfigMaxTitle(newValue);
                                  }}
                                />
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ all: "unset" }}>
                              <TextField
                                type={"number"}
                                value={configMaxTitleValue}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined"
                                placeholder="50 - 500"
                                InputProps={{
                                  style: {
                                    color: "#dcdcdc",
                                    fontSize: "1rem",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    padding: 0,
                                    borderRadius: "1.5rem",
                                    height: "100%",
                                    fontWeight: "bold",
                                  },
                                }}
                                sx={{
                                  flexGrow: 1,
                                  fieldset: {
                                    borderColor: "hsla(0,0%,100%,.1)",
                                    transition: "all .2s ease-out",
                                  },
                                  width: "100%",
                                }}
                                onChange={(e) => handleEditMaxTitle(e)}
                              />
                            </AccordionDetails>
                          </Accordion>
                        </Box>

                        <Box sx={{ marginBottom: "0.6rem" }}>
                          <Accordion
                            sx={{ all: "unset", width: "100%" }}
                            disableGutters
                            elevation={0}
                            expanded={configMinTitle}
                          >
                            <AccordionSummary
                              sx={{ all: "unset", width: "100%" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Tooltip
                                  arrow
                                  placement="right"
                                  title={t("upload.minTitleHint")}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: "#dcdcdc",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {t("upload.minTitle")}{" "}
                                    <InfoIcon
                                      sx={{ fontSize: "20px" }}
                                    ></InfoIcon>
                                  </Typography>
                                </Tooltip>

                                <Switch
                                  color="secondary"
                                  value={configMinTitle}
                                  checked={configMinTitle}
                                  onChange={() => {
                                    const newValue = !configMinTitle;
                                    if (!newValue) {
                                      localStorage.removeItem("minTitle");
                                    } else {
                                      setConfigReplaceTitle(false);
                                      localStorage.removeItem(
                                        "configReplaceTitle"
                                      );
                                      setConfigMaxTitle(false);
                                      localStorage.removeItem("maxTitle");
                                    }
                                    setConfigMinTitle(newValue);
                                  }}
                                />
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ all: "unset" }}>
                              <TextField
                                type={"number"}
                                value={configMinTitleValue}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined"
                                placeholder="5 - 200"
                                InputProps={{
                                  style: {
                                    color: "#dcdcdc",
                                    fontSize: "1rem",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    padding: 0,
                                    borderRadius: "1.5rem",
                                    height: "100%",
                                    fontWeight: "bold",
                                  },
                                }}
                                sx={{
                                  flexGrow: 1,
                                  fieldset: {
                                    borderColor: "hsla(0,0%,100%,.1)",
                                    transition: "all .2s ease-out",
                                  },
                                  width: "100%",
                                }}
                                onChange={(e) => handleEditMinTitle(e)}
                              />
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    sx={{ mb: "1rem" }}
                    expanded={expandedKeywords}
                    onChange={() => setExpandedKeywords((prev) => !prev)}
                  >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {t("upload.keywordsSettings")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ flexDirection: "column", gap: "1rem" }}>
                        <Box sx={{ marginBottom: "0.6rem" }}>
                          <Accordion
                            sx={{ all: "unset", width: "100%" }}
                            disableGutters
                            elevation={0}
                            expanded={configMaxKeywords}
                          >
                            <AccordionSummary
                              sx={{ all: "unset", width: "100%" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Tooltip
                                  arrow
                                  placement="right"
                                  title={t("upload.maxKeywordsHint")}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: "#dcdcdc",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {t("upload.maxKeywords")}{" "}
                                    <InfoIcon
                                      sx={{ fontSize: "20px" }}
                                    ></InfoIcon>
                                  </Typography>
                                </Tooltip>

                                <Switch
                                  color="secondary"
                                  value={configMaxKeywords}
                                  checked={configMaxKeywords}
                                  onChange={() => {
                                    const newValue = !configMaxKeywords;
                                    if (!newValue) {
                                      localStorage.removeItem("maxKeywords");
                                    } else {
                                      setConfigMinKeywords(false);
                                      localStorage.removeItem("minKeywords");
                                    }
                                    setConfigMaxKeywords(newValue);
                                  }}
                                />
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ all: "unset" }}>
                              <TextField
                                type={"number"}
                                value={configMaxKeywordsValue}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined"
                                placeholder="5 - 200"
                                InputProps={{
                                  style: {
                                    color: "#dcdcdc",
                                    fontSize: "1rem",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    padding: 0,
                                    borderRadius: "1.5rem",
                                    height: "100%",
                                    fontWeight: "bold",
                                  },
                                }}
                                sx={{
                                  flexGrow: 1,
                                  fieldset: {
                                    borderColor: "hsla(0,0%,100%,.1)",
                                    transition: "all .2s ease-out",
                                  },
                                  width: "100%",
                                }}
                                onChange={(e) => handleEditMaxKeywords(e)}
                              />
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                        <Box sx={{ marginBottom: "0.6rem" }}>
                          <Accordion
                            sx={{ all: "unset", width: "100%" }}
                            disableGutters
                            elevation={0}
                            expanded={configMinKeywords}
                          >
                            <AccordionSummary
                              sx={{ all: "unset", width: "100%" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Tooltip
                                  arrow
                                  placement="right"
                                  title={t("upload.minKeywordsHint")}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: "#dcdcdc",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {t("upload.minKeywords")}{" "}
                                    <InfoIcon
                                      sx={{ fontSize: "20px" }}
                                    ></InfoIcon>
                                  </Typography>
                                </Tooltip>

                                <Switch
                                  color="secondary"
                                  value={configMinKeywords}
                                  checked={configMinKeywords}
                                  onChange={() => {
                                    const newValue = !configMinKeywords;
                                    if (!newValue) {
                                      localStorage.removeItem("minKeywords");
                                    } else {
                                      setConfigMaxKeywords(false);
                                      localStorage.removeItem("maxKeywords");
                                    }
                                    setConfigMinKeywords(newValue);
                                  }}
                                />
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ all: "unset" }}>
                              <TextField
                                type={"number"}
                                value={configMinKeywordsValue}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined"
                                placeholder="5 - 40"
                                InputProps={{
                                  style: {
                                    color: "#dcdcdc",
                                    fontSize: "1rem",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    padding: 0,
                                    borderRadius: "1.5rem",
                                    height: "100%",
                                    fontWeight: "bold",
                                  },
                                }}
                                sx={{
                                  flexGrow: 1,
                                  fieldset: {
                                    borderColor: "hsla(0,0%,100%,.1)",
                                    transition: "all .2s ease-out",
                                  },
                                  width: "100%",
                                }}
                                onChange={(e) => handleEditMinKeywords(e)}
                              />
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                        <Box sx={{ marginBottom: "0.6rem" }}>
                          <Accordion
                            sx={{
                              all: "unset",
                              width: "100%",
                            }}
                            disableGutters
                            elevation={0}
                            expanded={configRequiredKeywords}
                          >
                            <AccordionSummary
                              sx={{ all: "unset", width: "100%" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Tooltip
                                  arrow
                                  placement="right"
                                  title={t("upload.requiredKeywordsHint")}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: "#dcdcdc",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {t("upload.requiredKeywords")}{" "}
                                    <InfoIcon
                                      sx={{ fontSize: "20px" }}
                                    ></InfoIcon>
                                  </Typography>
                                </Tooltip>
                                <Switch
                                  color="secondary"
                                  value={configRequiredKeywords}
                                  checked={configRequiredKeywords}
                                  onChange={() => {
                                    const newValue = !configRequiredKeywords;
                                    if (!newValue) {
                                      localStorage.removeItem(
                                        "requiredKeywords"
                                      );
                                    }
                                    setConfigRequiredKeywords(newValue);
                                  }}
                                />
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ all: "unset" }}>
                              <TextField
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={configReqKeywordsValue}
                                variant="outlined"
                                placeholder={t(
                                  "upload.requiredKeywordsPlaceholder"
                                )}
                                InputProps={{
                                  style: {
                                    color: "#dcdcdc",
                                    fontSize: "1rem",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    padding: 0,
                                    borderRadius: "1.5rem",
                                    height: "100%",
                                    fontWeight: "bold",
                                  },
                                }}
                                sx={{
                                  flexGrow: 1,
                                  fieldset: {
                                    borderColor: "hsla(0,0%,100%,.1)",
                                    transition: "all .2s ease-out",
                                  },
                                  width: "100%",
                                }}
                                onChange={(e) => handleEditRequiredKeywords(e)}
                              />
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                        <Box sx={{ marginBottom: "0.6rem" }}>
                          <Accordion
                            sx={{
                              all: "unset",
                              width: "100%",
                            }}
                            disableGutters
                            elevation={0}
                            expanded={configExcludedKeywords}
                          >
                            <AccordionSummary
                              sx={{ all: "unset", width: "100%" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <Tooltip
                                  arrow
                                  placement="right"
                                  title={t("upload.excludedKeywordsHint")}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: "#dcdcdc",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {t("upload.excludedKeywords")}{" "}
                                    <InfoIcon
                                      sx={{ fontSize: "20px" }}
                                    ></InfoIcon>
                                  </Typography>
                                </Tooltip>
                                <Switch
                                  color="secondary"
                                  value={configExcludedKeywords}
                                  checked={configExcludedKeywords}
                                  onChange={() => {
                                    const newValue = !configExcludedKeywords;
                                    if (!newValue) {
                                      localStorage.removeItem(
                                        "excludedKeywords"
                                      );
                                    }
                                    setConfigExcludedKeywords(newValue);
                                  }}
                                />
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ all: "unset" }}>
                              <TextField
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={configExcludedKeywordsValue}
                                variant="outlined"
                                placeholder={t(
                                  "upload.excludedKeywordsPlaceholder"
                                )}
                                InputProps={{
                                  style: {
                                    color: "#dcdcdc",
                                    fontSize: "1rem",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    padding: 0,
                                    borderRadius: "1.5rem",
                                    height: "100%",
                                    fontWeight: "bold",
                                  },
                                }}
                                sx={{
                                  flexGrow: 1,
                                  fieldset: {
                                    borderColor: "hsla(0,0%,100%,.1)",
                                    transition: "all .2s ease-out",
                                  },
                                  width: "100%",
                                }}
                                onChange={(e) => handleEditExcludedKeywords(e)}
                              />
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: "0.6rem",
                          }}
                        >
                          <Tooltip
                            arrow
                            placement="right"
                            title={t("upload.singleWordKeywordsHint")}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#dcdcdc", fontWeight: "bold" }}
                            >
                              {t("upload.singleWordKeywords")}{" "}
                              <InfoIcon sx={{ fontSize: "20px" }}></InfoIcon>
                            </Typography>
                          </Tooltip>

                          <Switch
                            color="secondary"
                            value={configSingleWord}
                            checked={configSingleWord}
                            onChange={() => {
                              const newValue = !configSingleWord;
                              if (newValue) {
                                localStorage.setItem("configSingleWord", true);
                              } else {
                                localStorage.removeItem("configSingleWord");
                              }
                              setConfigSingleWord(newValue);
                            }}
                          />
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>

                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    minWidth: { xs: "100%", md: "30rem" },
                  }}
                >
                  <FileUploader
                    disabled={queue > 0 ? true : false}
                    fileOrFiles={inputFiles}
                    multiple={true}
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                    onTypeError={(err) => errorHandling({ message: err })}
                    children={<UploadZone loading={uploadProcessing} />}
                    hoverTitle=" "
                    dropMessageStyle={{
                      borderRadius: "2rem",
                      backgroundColor: "rgba(255,255,255,0.4)",
                      border: "none",
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Fragment>
    ) : (
      <Fragment>
        {queue ? (
          <InfoPage
            type="uploading"
            message={uploadingMessage}
            queue={queue}
            uploaded={uploaded}
            action={setResetUploadState}
            icon={UploadFileIcon}
          />
        ) : (
          <InfoPage
            type="error"
            title={t("upload.failed")}
            message={uploadError?.message ?? t("upload.tryAgain")}
            action={setResetUploadState}
            buttonText={t("upload.retry")}
            icon={CloseIcon}
          />
        )}
      </Fragment>
    )
  ) : (
    <LogIn />
  );
}

export default Upload;
