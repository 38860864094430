import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { SessionContext } from "../context/SessionContext";
import Continue from "./Continue";

function Thanks({ supabase }) {
  const session = useContext(SessionContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (supabase && session?.user?.id && session?.user?.email) {
      // Check if the current user has a subscription or bundle for their ID created within the last 10 minutes and
      // an affiliate cookie set. If so, report conversion to Rewardful. Rewardful will only mark the conversion if
      // a Stripe customer has been created for this user in the last 24 hours and has an affiliate cookie set.
      const checkPurchase = async () => {
        const { data: subscriptions } = await supabase
          .from("subscriptions")
          .select("*")
          .eq("user_id", session?.user?.id)
          .gte(
            "created_at",
            new Date(Date.now() - 10 * 60 * 1000).toISOString()
          )
          .limit(1);
        const { data: bundles } = await supabase
          .from("bundles")
          .select("*")
          .eq("user_id", session?.user?.id)
          .gte(
            "created_at",
            new Date(Date.now() - 10 * 60 * 1000).toISOString()
          )
          .limit(1);
        const purchase =
          (subscriptions && subscriptions?.length > 0) ||
          (bundles && bundles?.length > 0);
        if (window?.Rewardful?.referral && purchase) {
          window?.rewardful("convert", { email: session?.user?.email });
        }
      };
      checkPurchase();
    }
  }, [session?.user, supabase]);

  return (
    <Continue
      primaryText={t("thanks.header")}
      buttonText={t("thanks.button")}
      timeout={300}
    />
  );
}

export default Thanks;
