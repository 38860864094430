export const getFormat = (formatName, selectedValues) => {
  const formats = {
    default: {
      fields: {
        file: { value: "name" },
        title: { value: "title" },
        description: { value: "description" },
        keywords: { value: "keywords" },
      },
      options: {
        semicolon: false,
      },
    },
    shutterstock: {
      fields: {
        Filename: { value: "name" },
        Description: { value: "title" },
        Keywords: { value: "keywords" },
        Categories: {
          value: selectedValues[0] || "",
          options: [
            { name: "None", value: " " },
            { name: "Miscellaneous", value: "Miscellaneous" },
            { name: "Abstract", value: "Abstract" },
            { name: "Animals/Wildlife", value: "Animals/Wildlife" },
            { name: "Nature", value: "Nature" },
            { name: "Backgrounds/Textures", value: "Backgrounds/Textures" },
            { name: "Objects", value: "Objects" },
            { name: "Beauty/Fashion", value: "Beauty/Fashion" },
            { name: "Parks/Outdoor", value: "Parks/Outdoor" },
            { name: "Buildings/Landmarks", value: "Buildings/Landmarks" },
            { name: "People", value: "People" },
            { name: "Business/Finance", value: "Business/Finance" },
            { name: "Religion", value: "Religion" },
            { name: "Celebrities", value: "Celebrities" },
            { name: "Science", value: "Science" },
            { name: "Education", value: "Education" },
            { name: "Signs/Symbols", value: "Signs/Symbols" },
            { name: "Food and Drink", value: "Food and Drink" },
            { name: "Sports/Recreation", value: "Sports/Recreation" },
            { name: "Healthcare/Medical", value: "Healthcare/Medical" },
            { name: "Technology", value: "Technology" },
            { name: "Holidays", value: "Holidays" },
            { name: "The Arts", value: "The Arts" },
            { name: "Industrial", value: "Industrial" },
            { name: "Transportation", value: "Transportation" },
            { name: "Interiors", value: "Interiors" },
            { name: "Vintage", value: "Vintage" },
          ],
        },
        Editorial: {
          value: selectedValues[1] || "Yes/No",
          options: [
            { name: "No", value: "No" },
            { name: "Yes", value: "Yes" },
          ],
        },
        MatureºContent: {
          value: selectedValues[2] || "Yes/No",
          options: [
            { name: "No", value: "No" },
            { name: "Yes", value: "Yes" },
          ],
        },
        Illustration: {
          value: selectedValues[3] || "Yes/No",
          options: [
            { name: "No", value: "No" },
            { name: "Yes", value: "Yes" },
          ],
        },
      },
      options: {
        semicolon: false,
      },
    },

    adobe_stock: {
      fields: {
        Filename: { value: "name" },
        Title: { value: "title" },
        Keywords: { value: "keywords" },
        Category: {
          value: selectedValues[0] || "",
          options: [
            { name: "None", value: " " },
            { name: "Animals", value: "1" },
            { name: "Buildings and Architecture", value: "2" },
            { name: "Business", value: "3" },
            { name: "Drinks", value: "4" },
            { name: "The Environment", value: "5" },
            { name: "States of Mind", value: "6" },
            { name: "Food", value: "7" },
            { name: "Graphic Resources", value: "8" },
            { name: "Hobbies and Leisure", value: "9" },
            { name: "Industry", value: "10" },
            { name: "Landscape", value: "11" },
            { name: "Lifestyle", value: "12" },
            { name: "People", value: "13" },
            { name: "Plants and Flowers", value: "14" },
            { name: "Culture and Religion", value: "15" },
            { name: "Science", value: "16" },
            { name: "Social Issues", value: "17" },
            { name: "Sports", value: "18" },
            { name: "Technology", value: "19" },
            { name: "Transport", value: "20" },
            { name: "Travel", value: "21" },
          ],
        },
        Releases: { value: "title" },
      },
      options: {
        semicolon: false,
      },
    },
    freepik: {
      fields: {
        Filename: { value: "name" },
        Title: { value: "title" },
        Tags: { value: "keywords" },
        Prompt: { value: "description" },
        Model: {
          value: selectedValues[0] || "",
          options: [
            { name: "None", value: " " },
            { name: "Dall-e 1", value: "Dall-e 1" },
            { name: "Dall-e 2", value: "Dall-e 2" },
            { name: "Leonardo", value: "Leonardo" },
            { name: "Midjourney 1", value: "Midjourney 1" },
            { name: "Midjourney 2", value: "Midjourney 2" },
            { name: "Midjourney 3", value: "Midjourney 3" },
            { name: "Midjourney 4", value: "Midjourney 4" },
            { name: "Midjourney 5", value: "Midjourney 5" },
            { name: "Midjourney 5.1", value: "Midjourney 5.1" },
            { name: "Midjourney 5.2", value: "Midjourney 5.2" },
            { name: "Midjourney 6", value: "Midjourney 6" },
            { name: "niji", value: "niji" },
            { name: "Stable Diffusion 1.4", value: "Stable Diffusion 1.4" },
            { name: "Stable Diffusion 1.5", value: "Stable Diffusion 1.5" },
            { name: "Stable Diffusion 2.0", value: "Stable Diffusion 2.0" },
            { name: "Stable Diffusion 2.1", value: "Stable Diffusion 2.1" },
          ],
        },
      },
      options: {
        semicolon: true,
      },
    },
    getty_images: {
      fields: {
        fileºname: { value: "name" },
        createdºdate: {
          value: selectedValues[0] || "",
          options: [
            {
              name: "Enter custom",
              value: " ",
            },
          ],
        },
        description: { value: "description" },
        country: {
          value: selectedValues[1] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        briefºcode: {
          value: selectedValues[2] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        title: { value: "title" },
        keywords: { value: "keywords" },
      },
      options: {
        semicolon: false,
      },
    },
    pond5: {
      fields: {
        originalfilename: { value: "name" },
        title: { value: "title" },
        description: { value: "description" },
        keywords: { value: "keywords" },
      },
      options: {
        semicolon: false,
      },
    },
    dreamstime: {
      fields: {
        Filename: { value: "name" },
        ImageºName: { value: "title" },
        Description: { value: "description" },
        Categoryº1: {
          value: selectedValues[0] || "0",
          options: [
            { name: "None", value: "0" },
            { name: "Abstract (Aerial)", value: "211" },
            { name: "Abstract (Backgrounds)", value: "112" },
            { name: "Abstract (Blurs)", value: "39" },
            { name: "Abstract (Colors)", value: "164" },
            { name: "Abstract (Competition)", value: "40" },
            { name: "Abstract (Craftsmanship)", value: "41" },
            { name: "Abstract (Danger)", value: "42" },
            { name: "Abstract (Exploration)", value: "43" },
            { name: "Abstract (Fun)", value: "158" },
            { name: "Abstract (Help)", value: "44" },
            { name: "Abstract (Love)", value: "149" },
            { name: "Abstract (Luxury)", value: "45" },
            { name: "Abstract (Mobile)", value: "187" },
            { name: "Abstract (Peace)", value: "46" },
            { name: "Abstract (Planetarium)", value: "165" },
            { name: "Abstract (Power)", value: "47" },
            { name: "Abstract (Purity)", value: "48" },
            { name: "Abstract (Religion)", value: "128" },
            { name: "Abstract (Seasonal & Holiday)", value: "155" },
            { name: "Abstract (Security)", value: "49" },
            { name: "Abstract (Sports)", value: "50" },
            { name: "Abstract (Stress)", value: "51" },
            { name: "Abstract (Success)", value: "52" },
            { name: "Abstract (Teamwork)", value: "53" },
            { name: "Abstract (Textures)", value: "141" },
            { name: "Abstract (Unique)", value: "54" },
            { name: "Animals (Birds)", value: "31" },
            { name: "Animals (Farm)", value: "33" },
            { name: "Animals (Insects)", value: "36" },
            { name: "Animals (Mammals)", value: "32" },
            { name: "Animals (Marine life)", value: "34" },
            { name: "Animals (Pets)", value: "30" },
            { name: "Animals (Reptiles & Amphibians)", value: "35" },
            { name: "Animals (Rodents)", value: "37" },
            { name: "Animals (Wildlife)", value: "168" },
            { name: "Arts & Architecture (Details)", value: "124" },
            {
              name: "Arts & Architecture (Generic architecture)",
              value: "71",
            },
            {
              name: "Arts & Architecture (Historic buildings)",
              value: "132",
            },
            { name: "Arts & Architecture (Home)", value: "153" },
            { name: "Arts & Architecture (Indoor)", value: "73" },
            { name: "Arts & Architecture (Landmarks)", value: "70" },
            { name: "Arts & Architecture (Modern buildings)", value: "131" },
            { name: "Arts & Architecture (Night scenes)", value: "130" },
            { name: "Arts & Architecture (Outdoor)", value: "72" },
            { name: "Arts & Architecture (Ruins & Ancient)", value: "174" },
            { name: "Arts & Architecture (Work places)", value: "154" },
            { name: "Business (Communications)", value: "79" },
            { name: "Business (Computers)", value: "78" },
            { name: "Business (Finance)", value: "80" },
            { name: "Business (Industries)", value: "77" },
            { name: "Business (Metaphors)", value: "83" },
            { name: "Business (Objects)", value: "84" },
            { name: "Business (People)", value: "75" },
            { name: "Business (Still-life)", value: "81" },
            { name: "Business (Teams)", value: "76" },
            { name: "Business (Transportation)", value: "82" },
            { name: "Business (Travel)", value: "85" },
            { name: "Editorial (Celebrities)", value: "178" },
            { name: "Editorial (Commercial)", value: "185" },
            { name: "Editorial (Events)", value: "179" },
            { name: "Editorial (Landmarks)", value: "184" },
            { name: "Editorial (People)", value: "180" },
            { name: "Editorial (Politics)", value: "181" },
            { name: "Editorial (Sports)", value: "182" },
            { name: "Editorial (Weather & Environment)", value: "183" },
            { name: "Holidays (Chinese New Year)", value: "204" },
            { name: "Holidays (Christmas)", value: "190" },
            { name: "Holidays (Cinco de Mayo)", value: "207" },
            { name: "Holidays (Diwali)", value: "203" },
            { name: "Holidays (Easter)", value: "193" },
            { name: "Holidays (Fathers Day)", value: "196" },
            { name: "Holidays (Halloween)", value: "192" },
            { name: "Holidays (Hanukkah)", value: "208" },
            { name: "Holidays (Mardi Gras)", value: "206" },
            { name: "Holidays (Mothers Day)", value: "195" },
            { name: "Holidays (New Years)", value: "189" },
            { name: "Holidays (Other)", value: "202" },
            { name: "Holidays (Ramadan)", value: "205" },
            { name: "Holidays (Thanksgiving)", value: "191" },
            { name: "Holidays (Valentines Day)", value: "194" },
            { name: "IT & C (Artificial Intelligence)", value: "210" },
            { name: "IT & C (Connectivity)", value: "110" },
            { name: "IT & C (Equipment)", value: "113" },
            { name: "IT & C (Internet)", value: "111" },
            { name: "IT & C (Networking)", value: "109" },
            { name: "Illustrations & Clipart (AI generated)", value: "212" },
            {
              name: "Illustrations & Clipart (3D & Computer generated)",
              value: "166",
            },
            {
              name: "Illustrations & Clipart (Hand drawn & Artistic)",
              value: "167",
            },
            { name: "Illustrations & Clipart (Illustrations)", value: "163" },
            { name: "Illustrations & Clipart (Vector)", value: "186" },
            { name: "Industries (Agriculture)", value: "101" },
            { name: "Industries (Architecture)", value: "89" },
            { name: "Industries (Banking)", value: "87" },
            { name: "Industries (Cargo & Shipping)", value: "93" },
            { name: "Industries (Communications)", value: "94" },
            { name: "Industries (Computers)", value: "91" },
            { name: "Industries (Construction)", value: "90" },
            { name: "Industries (Education)", value: "150" },
            { name: "Industries (Entertainment)", value: "136" },
            { name: "Industries (Environment)", value: "99" },
            { name: "Industries (Food & Beverages)", value: "127" },
            { name: "Industries (Healthcare & Medical)", value: "92" },
            { name: "Industries (Insurance)", value: "96" },
            { name: "Industries (Legal)", value: "95" },
            { name: "Industries (Manufacturing)", value: "100" },
            { name: "Industries (Military)", value: "102" },
            { name: "Industries (Oil and gas)", value: "161" },
            { name: "Industries (Power and energy)", value: "97" },
            { name: "Industries (Sports)", value: "157" },
            { name: "Industries (Transportation)", value: "98" },
            { name: "Industries (Travel)", value: "88" },
            { name: "Nature (Clouds and skies)", value: "22" },
            { name: "Nature (Deserts)", value: "17" },
            { name: "Nature (Details)", value: "14" },
            { name: "Nature (Fields & Meadows)", value: "27" },
            { name: "Nature (Flowers & Gardens)", value: "25" },
            { name: "Nature (Food ingredients)", value: "28" },
            { name: "Nature (Forests)", value: "18" },
            { name: "Nature (Fruits & Vegetables)", value: "137" },
            { name: "Nature (Generic vegetation)", value: "11" },
            { name: "Nature (Geologic and mineral)", value: "143" },
            { name: "Nature (Lakes and rivers)", value: "16" },
            { name: "Nature (Landscapes)", value: "146" },
            { name: "Nature (Mountains)", value: "15" },
            { name: "Nature (Plants and trees)", value: "12" },
            { name: "Nature (Sea & Ocean)", value: "19" },
            { name: "Nature (Seasons specific)", value: "26" },
            { name: "Nature (Sunsets & Sunrises)", value: "23" },
            { name: "Nature (Tropical)", value: "20" },
            { name: "Nature (Water)", value: "171" },
            { name: "Nature (Waterfalls)", value: "24" },
            { name: "Objects (Clothing & Accessories)", value: "142" },
            { name: "Objects (Electronics)", value: "147" },
            { name: "Objects (Home related)", value: "138" },
            { name: "Objects (Isolated)", value: "135" },
            { name: "Objects (Music and sound)", value: "151" },
            { name: "Objects (Other)", value: "145" },
            { name: "Objects (Retro)", value: "152" },
            { name: "Objects (Sports)", value: "156" },
            { name: "Objects (Still life)", value: "144" },
            { name: "Objects (Tools)", value: "140" },
            { name: "Objects (Toys)", value: "134" },
            { name: "People (Active)", value: "123" },
            { name: "People (Body parts)", value: "139" },
            { name: "People (Children)", value: "119" },
            { name: "People (Cosmetic & Makeup)", value: "175" },
            { name: "People (Couples)", value: "115" },
            { name: "People (Diversity)", value: "122" },
            { name: "People (Expressions)", value: "159" },
            { name: "People (Families)", value: "118" },
            { name: "People (Men)", value: "117" },
            { name: "People (Nudes)", value: "173" },
            { name: "People (Portraits)", value: "162" },
            { name: "People (Seniors)", value: "121" },
            { name: "People (Teens)", value: "120" },
            { name: "People (Women)", value: "116" },
            { name: "People (Workers)", value: "160" },
            { name: "Technology (Computers)", value: "105" },
            { name: "Technology (Connections)", value: "106" },
            { name: "Technology (Electronics)", value: "129" },
            { name: "Technology (Other)", value: "148" },
            { name: "Technology (Retro)", value: "107" },
            { name: "Technology (Science)", value: "209" },
            { name: "Technology (Telecommunications)", value: "104" },
            { name: "Travel (Africa)", value: "56" },
            { name: "Travel (America)", value: "58" },
            { name: "Travel (Antarctica)", value: "176" },
            { name: "Travel (Arts & Architecture)", value: "65" },
            { name: "Travel (Asia)", value: "57" },
            { name: "Travel (Australasian)", value: "60" },
            { name: "Travel (Cruise)", value: "62" },
            { name: "Travel (Cuisine)", value: "63" },
            { name: "Travel (Currencies)", value: "67" },
            { name: "Travel (Destination scenics)", value: "61" },
            { name: "Travel (Europe)", value: "59" },
            { name: "Travel (Flags)", value: "68" },
            { name: "Travel (Resorts)", value: "64" },
            { name: "Travel (Tropical)", value: "66" },
            { name: "Web Design Graphics (Banners)", value: "201" },
            { name: "Web Design Graphics (Buttons)", value: "200" },
            {
              name: "Web Design Graphics (Web Backgrounds & Textures)",
              value: "199",
            },
            { name: "Web Design Graphics (Web Icons)", value: "198" },
          ],
        },
        Categoryº2: {
          value: selectedValues[1] || "0",
          options: [
            { name: "None", value: "0" },
            { name: "Abstract (Aerial)", value: "211" },
            { name: "Abstract (Backgrounds)", value: "112" },
            { name: "Abstract (Blurs)", value: "39" },
            { name: "Abstract (Colors)", value: "164" },
            { name: "Abstract (Competition)", value: "40" },
            { name: "Abstract (Craftsmanship)", value: "41" },
            { name: "Abstract (Danger)", value: "42" },
            { name: "Abstract (Exploration)", value: "43" },
            { name: "Abstract (Fun)", value: "158" },
            { name: "Abstract (Help)", value: "44" },
            { name: "Abstract (Love)", value: "149" },
            { name: "Abstract (Luxury)", value: "45" },
            { name: "Abstract (Mobile)", value: "187" },
            { name: "Abstract (Peace)", value: "46" },
            { name: "Abstract (Planetarium)", value: "165" },
            { name: "Abstract (Power)", value: "47" },
            { name: "Abstract (Purity)", value: "48" },
            { name: "Abstract (Religion)", value: "128" },
            { name: "Abstract (Seasonal & Holiday)", value: "155" },
            { name: "Abstract (Security)", value: "49" },
            { name: "Abstract (Sports)", value: "50" },
            { name: "Abstract (Stress)", value: "51" },
            { name: "Abstract (Success)", value: "52" },
            { name: "Abstract (Teamwork)", value: "53" },
            { name: "Abstract (Textures)", value: "141" },
            { name: "Abstract (Unique)", value: "54" },
            { name: "Animals (Birds)", value: "31" },
            { name: "Animals (Farm)", value: "33" },
            { name: "Animals (Insects)", value: "36" },
            { name: "Animals (Mammals)", value: "32" },
            { name: "Animals (Marine life)", value: "34" },
            { name: "Animals (Pets)", value: "30" },
            { name: "Animals (Reptiles & Amphibians)", value: "35" },
            { name: "Animals (Rodents)", value: "37" },
            { name: "Animals (Wildlife)", value: "168" },
            { name: "Arts & Architecture (Details)", value: "124" },
            {
              name: "Arts & Architecture (Generic architecture)",
              value: "71",
            },
            {
              name: "Arts & Architecture (Historic buildings)",
              value: "132",
            },
            { name: "Arts & Architecture (Home)", value: "153" },
            { name: "Arts & Architecture (Indoor)", value: "73" },
            { name: "Arts & Architecture (Landmarks)", value: "70" },
            { name: "Arts & Architecture (Modern buildings)", value: "131" },
            { name: "Arts & Architecture (Night scenes)", value: "130" },
            { name: "Arts & Architecture (Outdoor)", value: "72" },
            { name: "Arts & Architecture (Ruins & Ancient)", value: "174" },
            { name: "Arts & Architecture (Work places)", value: "154" },
            { name: "Business (Communications)", value: "79" },
            { name: "Business (Computers)", value: "78" },
            { name: "Business (Finance)", value: "80" },
            { name: "Business (Industries)", value: "77" },
            { name: "Business (Metaphors)", value: "83" },
            { name: "Business (Objects)", value: "84" },
            { name: "Business (People)", value: "75" },
            { name: "Business (Still-life)", value: "81" },
            { name: "Business (Teams)", value: "76" },
            { name: "Business (Transportation)", value: "82" },
            { name: "Business (Travel)", value: "85" },
            { name: "Editorial (Celebrities)", value: "178" },
            { name: "Editorial (Commercial)", value: "185" },
            { name: "Editorial (Events)", value: "179" },
            { name: "Editorial (Landmarks)", value: "184" },
            { name: "Editorial (People)", value: "180" },
            { name: "Editorial (Politics)", value: "181" },
            { name: "Editorial (Sports)", value: "182" },
            { name: "Editorial (Weather & Environment)", value: "183" },
            { name: "Holidays (Chinese New Year)", value: "204" },
            { name: "Holidays (Christmas)", value: "190" },
            { name: "Holidays (Cinco de Mayo)", value: "207" },
            { name: "Holidays (Diwali)", value: "203" },
            { name: "Holidays (Easter)", value: "193" },
            { name: "Holidays (Fathers Day)", value: "196" },
            { name: "Holidays (Halloween)", value: "192" },
            { name: "Holidays (Hanukkah)", value: "208" },
            { name: "Holidays (Mardi Gras)", value: "206" },
            { name: "Holidays (Mothers Day)", value: "195" },
            { name: "Holidays (New Years)", value: "189" },
            { name: "Holidays (Other)", value: "202" },
            { name: "Holidays (Ramadan)", value: "205" },
            { name: "Holidays (Thanksgiving)", value: "191" },
            { name: "Holidays (Valentines Day)", value: "194" },
            { name: "IT & C (Artificial Intelligence)", value: "210" },
            { name: "IT & C (Connectivity)", value: "110" },
            { name: "IT & C (Equipment)", value: "113" },
            { name: "IT & C (Internet)", value: "111" },
            { name: "IT & C (Networking)", value: "109" },
            { name: "Illustrations & Clipart (AI generated)", value: "212" },
            {
              name: "Illustrations & Clipart (3D & Computer generated)",
              value: "166",
            },
            {
              name: "Illustrations & Clipart (Hand drawn & Artistic)",
              value: "167",
            },
            { name: "Illustrations & Clipart (Illustrations)", value: "163" },
            { name: "Illustrations & Clipart (Vector)", value: "186" },
            { name: "Industries (Agriculture)", value: "101" },
            { name: "Industries (Architecture)", value: "89" },
            { name: "Industries (Banking)", value: "87" },
            { name: "Industries (Cargo & Shipping)", value: "93" },
            { name: "Industries (Communications)", value: "94" },
            { name: "Industries (Computers)", value: "91" },
            { name: "Industries (Construction)", value: "90" },
            { name: "Industries (Education)", value: "150" },
            { name: "Industries (Entertainment)", value: "136" },
            { name: "Industries (Environment)", value: "99" },
            { name: "Industries (Food & Beverages)", value: "127" },
            { name: "Industries (Healthcare & Medical)", value: "92" },
            { name: "Industries (Insurance)", value: "96" },
            { name: "Industries (Legal)", value: "95" },
            { name: "Industries (Manufacturing)", value: "100" },
            { name: "Industries (Military)", value: "102" },
            { name: "Industries (Oil and gas)", value: "161" },
            { name: "Industries (Power and energy)", value: "97" },
            { name: "Industries (Sports)", value: "157" },
            { name: "Industries (Transportation)", value: "98" },
            { name: "Industries (Travel)", value: "88" },
            { name: "Nature (Clouds and skies)", value: "22" },
            { name: "Nature (Deserts)", value: "17" },
            { name: "Nature (Details)", value: "14" },
            { name: "Nature (Fields & Meadows)", value: "27" },
            { name: "Nature (Flowers & Gardens)", value: "25" },
            { name: "Nature (Food ingredients)", value: "28" },
            { name: "Nature (Forests)", value: "18" },
            { name: "Nature (Fruits & Vegetables)", value: "137" },
            { name: "Nature (Generic vegetation)", value: "11" },
            { name: "Nature (Geologic and mineral)", value: "143" },
            { name: "Nature (Lakes and rivers)", value: "16" },
            { name: "Nature (Landscapes)", value: "146" },
            { name: "Nature (Mountains)", value: "15" },
            { name: "Nature (Plants and trees)", value: "12" },
            { name: "Nature (Sea & Ocean)", value: "19" },
            { name: "Nature (Seasons specific)", value: "26" },
            { name: "Nature (Sunsets & Sunrises)", value: "23" },
            { name: "Nature (Tropical)", value: "20" },
            { name: "Nature (Water)", value: "171" },
            { name: "Nature (Waterfalls)", value: "24" },
            { name: "Objects (Clothing & Accessories)", value: "142" },
            { name: "Objects (Electronics)", value: "147" },
            { name: "Objects (Home related)", value: "138" },
            { name: "Objects (Isolated)", value: "135" },
            { name: "Objects (Music and sound)", value: "151" },
            { name: "Objects (Other)", value: "145" },
            { name: "Objects (Retro)", value: "152" },
            { name: "Objects (Sports)", value: "156" },
            { name: "Objects (Still life)", value: "144" },
            { name: "Objects (Tools)", value: "140" },
            { name: "Objects (Toys)", value: "134" },
            { name: "People (Active)", value: "123" },
            { name: "People (Body parts)", value: "139" },
            { name: "People (Children)", value: "119" },
            { name: "People (Cosmetic & Makeup)", value: "175" },
            { name: "People (Couples)", value: "115" },
            { name: "People (Diversity)", value: "122" },
            { name: "People (Expressions)", value: "159" },
            { name: "People (Families)", value: "118" },
            { name: "People (Men)", value: "117" },
            { name: "People (Nudes)", value: "173" },
            { name: "People (Portraits)", value: "162" },
            { name: "People (Seniors)", value: "121" },
            { name: "People (Teens)", value: "120" },
            { name: "People (Women)", value: "116" },
            { name: "People (Workers)", value: "160" },
            { name: "Technology (Computers)", value: "105" },
            { name: "Technology (Connections)", value: "106" },
            { name: "Technology (Electronics)", value: "129" },
            { name: "Technology (Other)", value: "148" },
            { name: "Technology (Retro)", value: "107" },
            { name: "Technology (Science)", value: "209" },
            { name: "Technology (Telecommunications)", value: "104" },
            { name: "Travel (Africa)", value: "56" },
            { name: "Travel (America)", value: "58" },
            { name: "Travel (Antarctica)", value: "176" },
            { name: "Travel (Arts & Architecture)", value: "65" },
            { name: "Travel (Asia)", value: "57" },
            { name: "Travel (Australasian)", value: "60" },
            { name: "Travel (Cruise)", value: "62" },
            { name: "Travel (Cuisine)", value: "63" },
            { name: "Travel (Currencies)", value: "67" },
            { name: "Travel (Destination scenics)", value: "61" },
            { name: "Travel (Europe)", value: "59" },
            { name: "Travel (Flags)", value: "68" },
            { name: "Travel (Resorts)", value: "64" },
            { name: "Travel (Tropical)", value: "66" },
            { name: "Web Design Graphics (Banners)", value: "201" },
            { name: "Web Design Graphics (Buttons)", value: "200" },
            {
              name: "Web Design Graphics (Web Backgrounds & Textures)",
              value: "199",
            },
            { name: "Web Design Graphics (Web Icons)", value: "198" },
          ],
        },
        Categoryº3: {
          value: selectedValues[2] || "0",
          options: [
            { name: "None", value: "0" },
            { name: "Abstract (Aerial)", value: "211" },
            { name: "Abstract (Backgrounds)", value: "112" },
            { name: "Abstract (Blurs)", value: "39" },
            { name: "Abstract (Colors)", value: "164" },
            { name: "Abstract (Competition)", value: "40" },
            { name: "Abstract (Craftsmanship)", value: "41" },
            { name: "Abstract (Danger)", value: "42" },
            { name: "Abstract (Exploration)", value: "43" },
            { name: "Abstract (Fun)", value: "158" },
            { name: "Abstract (Help)", value: "44" },
            { name: "Abstract (Love)", value: "149" },
            { name: "Abstract (Luxury)", value: "45" },
            { name: "Abstract (Mobile)", value: "187" },
            { name: "Abstract (Peace)", value: "46" },
            { name: "Abstract (Planetarium)", value: "165" },
            { name: "Abstract (Power)", value: "47" },
            { name: "Abstract (Purity)", value: "48" },
            { name: "Abstract (Religion)", value: "128" },
            { name: "Abstract (Seasonal & Holiday)", value: "155" },
            { name: "Abstract (Security)", value: "49" },
            { name: "Abstract (Sports)", value: "50" },
            { name: "Abstract (Stress)", value: "51" },
            { name: "Abstract (Success)", value: "52" },
            { name: "Abstract (Teamwork)", value: "53" },
            { name: "Abstract (Textures)", value: "141" },
            { name: "Abstract (Unique)", value: "54" },
            { name: "Animals (Birds)", value: "31" },
            { name: "Animals (Farm)", value: "33" },
            { name: "Animals (Insects)", value: "36" },
            { name: "Animals (Mammals)", value: "32" },
            { name: "Animals (Marine life)", value: "34" },
            { name: "Animals (Pets)", value: "30" },
            { name: "Animals (Reptiles & Amphibians)", value: "35" },
            { name: "Animals (Rodents)", value: "37" },
            { name: "Animals (Wildlife)", value: "168" },
            { name: "Arts & Architecture (Details)", value: "124" },
            {
              name: "Arts & Architecture (Generic architecture)",
              value: "71",
            },
            {
              name: "Arts & Architecture (Historic buildings)",
              value: "132",
            },
            { name: "Arts & Architecture (Home)", value: "153" },
            { name: "Arts & Architecture (Indoor)", value: "73" },
            { name: "Arts & Architecture (Landmarks)", value: "70" },
            { name: "Arts & Architecture (Modern buildings)", value: "131" },
            { name: "Arts & Architecture (Night scenes)", value: "130" },
            { name: "Arts & Architecture (Outdoor)", value: "72" },
            { name: "Arts & Architecture (Ruins & Ancient)", value: "174" },
            { name: "Arts & Architecture (Work places)", value: "154" },
            { name: "Business (Communications)", value: "79" },
            { name: "Business (Computers)", value: "78" },
            { name: "Business (Finance)", value: "80" },
            { name: "Business (Industries)", value: "77" },
            { name: "Business (Metaphors)", value: "83" },
            { name: "Business (Objects)", value: "84" },
            { name: "Business (People)", value: "75" },
            { name: "Business (Still-life)", value: "81" },
            { name: "Business (Teams)", value: "76" },
            { name: "Business (Transportation)", value: "82" },
            { name: "Business (Travel)", value: "85" },
            { name: "Editorial (Celebrities)", value: "178" },
            { name: "Editorial (Commercial)", value: "185" },
            { name: "Editorial (Events)", value: "179" },
            { name: "Editorial (Landmarks)", value: "184" },
            { name: "Editorial (People)", value: "180" },
            { name: "Editorial (Politics)", value: "181" },
            { name: "Editorial (Sports)", value: "182" },
            { name: "Editorial (Weather & Environment)", value: "183" },
            { name: "Holidays (Chinese New Year)", value: "204" },
            { name: "Holidays (Christmas)", value: "190" },
            { name: "Holidays (Cinco de Mayo)", value: "207" },
            { name: "Holidays (Diwali)", value: "203" },
            { name: "Holidays (Easter)", value: "193" },
            { name: "Holidays (Fathers Day)", value: "196" },
            { name: "Holidays (Halloween)", value: "192" },
            { name: "Holidays (Hanukkah)", value: "208" },
            { name: "Holidays (Mardi Gras)", value: "206" },
            { name: "Holidays (Mothers Day)", value: "195" },
            { name: "Holidays (New Years)", value: "189" },
            { name: "Holidays (Other)", value: "202" },
            { name: "Holidays (Ramadan)", value: "205" },
            { name: "Holidays (Thanksgiving)", value: "191" },
            { name: "Holidays (Valentines Day)", value: "194" },
            { name: "IT & C (Artificial Intelligence)", value: "210" },
            { name: "IT & C (Connectivity)", value: "110" },
            { name: "IT & C (Equipment)", value: "113" },
            { name: "IT & C (Internet)", value: "111" },
            { name: "IT & C (Networking)", value: "109" },
            { name: "Illustrations & Clipart (AI generated)", value: "212" },
            {
              name: "Illustrations & Clipart (3D & Computer generated)",
              value: "166",
            },
            {
              name: "Illustrations & Clipart (Hand drawn & Artistic)",
              value: "167",
            },
            { name: "Illustrations & Clipart (Illustrations)", value: "163" },
            { name: "Illustrations & Clipart (Vector)", value: "186" },
            { name: "Industries (Agriculture)", value: "101" },
            { name: "Industries (Architecture)", value: "89" },
            { name: "Industries (Banking)", value: "87" },
            { name: "Industries (Cargo & Shipping)", value: "93" },
            { name: "Industries (Communications)", value: "94" },
            { name: "Industries (Computers)", value: "91" },
            { name: "Industries (Construction)", value: "90" },
            { name: "Industries (Education)", value: "150" },
            { name: "Industries (Entertainment)", value: "136" },
            { name: "Industries (Environment)", value: "99" },
            { name: "Industries (Food & Beverages)", value: "127" },
            { name: "Industries (Healthcare & Medical)", value: "92" },
            { name: "Industries (Insurance)", value: "96" },
            { name: "Industries (Legal)", value: "95" },
            { name: "Industries (Manufacturing)", value: "100" },
            { name: "Industries (Military)", value: "102" },
            { name: "Industries (Oil and gas)", value: "161" },
            { name: "Industries (Power and energy)", value: "97" },
            { name: "Industries (Sports)", value: "157" },
            { name: "Industries (Transportation)", value: "98" },
            { name: "Industries (Travel)", value: "88" },
            { name: "Nature (Clouds and skies)", value: "22" },
            { name: "Nature (Deserts)", value: "17" },
            { name: "Nature (Details)", value: "14" },
            { name: "Nature (Fields & Meadows)", value: "27" },
            { name: "Nature (Flowers & Gardens)", value: "25" },
            { name: "Nature (Food ingredients)", value: "28" },
            { name: "Nature (Forests)", value: "18" },
            { name: "Nature (Fruits & Vegetables)", value: "137" },
            { name: "Nature (Generic vegetation)", value: "11" },
            { name: "Nature (Geologic and mineral)", value: "143" },
            { name: "Nature (Lakes and rivers)", value: "16" },
            { name: "Nature (Landscapes)", value: "146" },
            { name: "Nature (Mountains)", value: "15" },
            { name: "Nature (Plants and trees)", value: "12" },
            { name: "Nature (Sea & Ocean)", value: "19" },
            { name: "Nature (Seasons specific)", value: "26" },
            { name: "Nature (Sunsets & Sunrises)", value: "23" },
            { name: "Nature (Tropical)", value: "20" },
            { name: "Nature (Water)", value: "171" },
            { name: "Nature (Waterfalls)", value: "24" },
            { name: "Objects (Clothing & Accessories)", value: "142" },
            { name: "Objects (Electronics)", value: "147" },
            { name: "Objects (Home related)", value: "138" },
            { name: "Objects (Isolated)", value: "135" },
            { name: "Objects (Music and sound)", value: "151" },
            { name: "Objects (Other)", value: "145" },
            { name: "Objects (Retro)", value: "152" },
            { name: "Objects (Sports)", value: "156" },
            { name: "Objects (Still life)", value: "144" },
            { name: "Objects (Tools)", value: "140" },
            { name: "Objects (Toys)", value: "134" },
            { name: "People (Active)", value: "123" },
            { name: "People (Body parts)", value: "139" },
            { name: "People (Children)", value: "119" },
            { name: "People (Cosmetic & Makeup)", value: "175" },
            { name: "People (Couples)", value: "115" },
            { name: "People (Diversity)", value: "122" },
            { name: "People (Expressions)", value: "159" },
            { name: "People (Families)", value: "118" },
            { name: "People (Men)", value: "117" },
            { name: "People (Nudes)", value: "173" },
            { name: "People (Portraits)", value: "162" },
            { name: "People (Seniors)", value: "121" },
            { name: "People (Teens)", value: "120" },
            { name: "People (Women)", value: "116" },
            { name: "People (Workers)", value: "160" },
            { name: "Technology (Computers)", value: "105" },
            { name: "Technology (Connections)", value: "106" },
            { name: "Technology (Electronics)", value: "129" },
            { name: "Technology (Other)", value: "148" },
            { name: "Technology (Retro)", value: "107" },
            { name: "Technology (Science)", value: "209" },
            { name: "Technology (Telecommunications)", value: "104" },
            { name: "Travel (Africa)", value: "56" },
            { name: "Travel (America)", value: "58" },
            { name: "Travel (Antarctica)", value: "176" },
            { name: "Travel (Arts & Architecture)", value: "65" },
            { name: "Travel (Asia)", value: "57" },
            { name: "Travel (Australasian)", value: "60" },
            { name: "Travel (Cruise)", value: "62" },
            { name: "Travel (Cuisine)", value: "63" },
            { name: "Travel (Currencies)", value: "67" },
            { name: "Travel (Destination scenics)", value: "61" },
            { name: "Travel (Europe)", value: "59" },
            { name: "Travel (Flags)", value: "68" },
            { name: "Travel (Resorts)", value: "64" },
            { name: "Travel (Tropical)", value: "66" },
            { name: "Web Design Graphics (Banners)", value: "201" },
            { name: "Web Design Graphics (Buttons)", value: "200" },
            {
              name: "Web Design Graphics (Web Backgrounds & Textures)",
              value: "199",
            },
            { name: "Web Design Graphics (Web Icons)", value: "198" },
          ],
        },
        keywords: { value: "keywords" },
        Free: {
          value: selectedValues[3] || "0",
          options: [
            { name: "No", value: "0" },
            { name: "Yes", value: "1" },
          ],
        },
        W$EL: {
          value: selectedValues[4] || "0",
          options: [
            { name: "No", value: "0" },
            { name: "Yes", value: "1" },
          ],
        },
        P$EL: {
          value: selectedValues[5] || "0",
          options: [
            { name: "No", value: "0" },
            { name: "Yes", value: "1" },
          ],
        },
        SR$EL: {
          value: selectedValues[6] || "0",
          options: [
            { name: "No", value: "0" },
            { name: "Yes", value: "1" },
          ],
        },
        SR$Price: {
          value: selectedValues[7] || "0",
          options: [
            { name: "No", value: "0" },
            { name: "Yes", value: "1" },
          ],
        },
        Editorial: {
          value: selectedValues[8] || "0",
          options: [
            { name: "No", value: "0" },
            { name: "Yes", value: "1" },
          ],
        },
        MRºdocºIds: {
          value: selectedValues[9] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        PrºDocs: {
          value: selectedValues[10] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
      },
      options: {
        semicolon: false,
      },
    },
    dreamstime_video: {
      fields: {
        Filename: { value: "name" },
        VideoºName: { value: "title" },
        Description: { value: "description" },
        Categoryº1: {
          value: selectedValues[0] || "0",
          options: [
            { name: "None", value: "0" },
            { name: "Abstract (Aerial)", value: "211" },
            { name: "Abstract (Backgrounds)", value: "112" },
            { name: "Abstract (Blurs)", value: "39" },
            { name: "Abstract (Colors)", value: "164" },
            { name: "Abstract (Competition)", value: "40" },
            { name: "Abstract (Craftsmanship)", value: "41" },
            { name: "Abstract (Danger)", value: "42" },
            { name: "Abstract (Exploration)", value: "43" },
            { name: "Abstract (Fun)", value: "158" },
            { name: "Abstract (Help)", value: "44" },
            { name: "Abstract (Love)", value: "149" },
            { name: "Abstract (Luxury)", value: "45" },
            { name: "Abstract (Mobile)", value: "187" },
            { name: "Abstract (Peace)", value: "46" },
            { name: "Abstract (Planetarium)", value: "165" },
            { name: "Abstract (Power)", value: "47" },
            { name: "Abstract (Purity)", value: "48" },
            { name: "Abstract (Religion)", value: "128" },
            { name: "Abstract (Seasonal & Holiday)", value: "155" },
            { name: "Abstract (Security)", value: "49" },
            { name: "Abstract (Sports)", value: "50" },
            { name: "Abstract (Stress)", value: "51" },
            { name: "Abstract (Success)", value: "52" },
            { name: "Abstract (Teamwork)", value: "53" },
            { name: "Abstract (Textures)", value: "141" },
            { name: "Abstract (Unique)", value: "54" },
            { name: "Animals (Birds)", value: "31" },
            { name: "Animals (Farm)", value: "33" },
            { name: "Animals (Insects)", value: "36" },
            { name: "Animals (Mammals)", value: "32" },
            { name: "Animals (Marine life)", value: "34" },
            { name: "Animals (Pets)", value: "30" },
            { name: "Animals (Reptiles & Amphibians)", value: "35" },
            { name: "Animals (Rodents)", value: "37" },
            { name: "Animals (Wildlife)", value: "168" },
            { name: "Arts & Architecture (Details)", value: "124" },
            {
              name: "Arts & Architecture (Generic architecture)",
              value: "71",
            },
            {
              name: "Arts & Architecture (Historic buildings)",
              value: "132",
            },
            { name: "Arts & Architecture (Home)", value: "153" },
            { name: "Arts & Architecture (Indoor)", value: "73" },
            { name: "Arts & Architecture (Landmarks)", value: "70" },
            { name: "Arts & Architecture (Modern buildings)", value: "131" },
            { name: "Arts & Architecture (Night scenes)", value: "130" },
            { name: "Arts & Architecture (Outdoor)", value: "72" },
            { name: "Arts & Architecture (Ruins & Ancient)", value: "174" },
            { name: "Arts & Architecture (Work places)", value: "154" },
            { name: "Business (Communications)", value: "79" },
            { name: "Business (Computers)", value: "78" },
            { name: "Business (Finance)", value: "80" },
            { name: "Business (Industries)", value: "77" },
            { name: "Business (Metaphors)", value: "83" },
            { name: "Business (Objects)", value: "84" },
            { name: "Business (People)", value: "75" },
            { name: "Business (Still-life)", value: "81" },
            { name: "Business (Teams)", value: "76" },
            { name: "Business (Transportation)", value: "82" },
            { name: "Business (Travel)", value: "85" },
            { name: "Editorial (Celebrities)", value: "178" },
            { name: "Editorial (Commercial)", value: "185" },
            { name: "Editorial (Events)", value: "179" },
            { name: "Editorial (Landmarks)", value: "184" },
            { name: "Editorial (People)", value: "180" },
            { name: "Editorial (Politics)", value: "181" },
            { name: "Editorial (Sports)", value: "182" },
            { name: "Editorial (Weather & Environment)", value: "183" },
            { name: "Holidays (Chinese New Year)", value: "204" },
            { name: "Holidays (Christmas)", value: "190" },
            { name: "Holidays (Cinco de Mayo)", value: "207" },
            { name: "Holidays (Diwali)", value: "203" },
            { name: "Holidays (Easter)", value: "193" },
            { name: "Holidays (Fathers Day)", value: "196" },
            { name: "Holidays (Halloween)", value: "192" },
            { name: "Holidays (Hanukkah)", value: "208" },
            { name: "Holidays (Mardi Gras)", value: "206" },
            { name: "Holidays (Mothers Day)", value: "195" },
            { name: "Holidays (New Years)", value: "189" },
            { name: "Holidays (Other)", value: "202" },
            { name: "Holidays (Ramadan)", value: "205" },
            { name: "Holidays (Thanksgiving)", value: "191" },
            { name: "Holidays (Valentines Day)", value: "194" },
            { name: "IT & C (Artificial Intelligence)", value: "210" },
            { name: "IT & C (Connectivity)", value: "110" },
            { name: "IT & C (Equipment)", value: "113" },
            { name: "IT & C (Internet)", value: "111" },
            { name: "IT & C (Networking)", value: "109" },
            { name: "Illustrations & Clipart (AI generated)", value: "212" },
            {
              name: "Illustrations & Clipart (3D & Computer generated)",
              value: "166",
            },
            {
              name: "Illustrations & Clipart (Hand drawn & Artistic)",
              value: "167",
            },
            { name: "Illustrations & Clipart (Illustrations)", value: "163" },
            { name: "Illustrations & Clipart (Vector)", value: "186" },
            { name: "Industries (Agriculture)", value: "101" },
            { name: "Industries (Architecture)", value: "89" },
            { name: "Industries (Banking)", value: "87" },
            { name: "Industries (Cargo & Shipping)", value: "93" },
            { name: "Industries (Communications)", value: "94" },
            { name: "Industries (Computers)", value: "91" },
            { name: "Industries (Construction)", value: "90" },
            { name: "Industries (Education)", value: "150" },
            { name: "Industries (Entertainment)", value: "136" },
            { name: "Industries (Environment)", value: "99" },
            { name: "Industries (Food & Beverages)", value: "127" },
            { name: "Industries (Healthcare & Medical)", value: "92" },
            { name: "Industries (Insurance)", value: "96" },
            { name: "Industries (Legal)", value: "95" },
            { name: "Industries (Manufacturing)", value: "100" },
            { name: "Industries (Military)", value: "102" },
            { name: "Industries (Oil and gas)", value: "161" },
            { name: "Industries (Power and energy)", value: "97" },
            { name: "Industries (Sports)", value: "157" },
            { name: "Industries (Transportation)", value: "98" },
            { name: "Industries (Travel)", value: "88" },
            { name: "Nature (Clouds and skies)", value: "22" },
            { name: "Nature (Deserts)", value: "17" },
            { name: "Nature (Details)", value: "14" },
            { name: "Nature (Fields & Meadows)", value: "27" },
            { name: "Nature (Flowers & Gardens)", value: "25" },
            { name: "Nature (Food ingredients)", value: "28" },
            { name: "Nature (Forests)", value: "18" },
            { name: "Nature (Fruits & Vegetables)", value: "137" },
            { name: "Nature (Generic vegetation)", value: "11" },
            { name: "Nature (Geologic and mineral)", value: "143" },
            { name: "Nature (Lakes and rivers)", value: "16" },
            { name: "Nature (Landscapes)", value: "146" },
            { name: "Nature (Mountains)", value: "15" },
            { name: "Nature (Plants and trees)", value: "12" },
            { name: "Nature (Sea & Ocean)", value: "19" },
            { name: "Nature (Seasons specific)", value: "26" },
            { name: "Nature (Sunsets & Sunrises)", value: "23" },
            { name: "Nature (Tropical)", value: "20" },
            { name: "Nature (Water)", value: "171" },
            { name: "Nature (Waterfalls)", value: "24" },
            { name: "Objects (Clothing & Accessories)", value: "142" },
            { name: "Objects (Electronics)", value: "147" },
            { name: "Objects (Home related)", value: "138" },
            { name: "Objects (Isolated)", value: "135" },
            { name: "Objects (Music and sound)", value: "151" },
            { name: "Objects (Other)", value: "145" },
            { name: "Objects (Retro)", value: "152" },
            { name: "Objects (Sports)", value: "156" },
            { name: "Objects (Still life)", value: "144" },
            { name: "Objects (Tools)", value: "140" },
            { name: "Objects (Toys)", value: "134" },
            { name: "People (Active)", value: "123" },
            { name: "People (Body parts)", value: "139" },
            { name: "People (Children)", value: "119" },
            { name: "People (Cosmetic & Makeup)", value: "175" },
            { name: "People (Couples)", value: "115" },
            { name: "People (Diversity)", value: "122" },
            { name: "People (Expressions)", value: "159" },
            { name: "People (Families)", value: "118" },
            { name: "People (Men)", value: "117" },
            { name: "People (Nudes)", value: "173" },
            { name: "People (Portraits)", value: "162" },
            { name: "People (Seniors)", value: "121" },
            { name: "People (Teens)", value: "120" },
            { name: "People (Women)", value: "116" },
            { name: "People (Workers)", value: "160" },
            { name: "Technology (Computers)", value: "105" },
            { name: "Technology (Connections)", value: "106" },
            { name: "Technology (Electronics)", value: "129" },
            { name: "Technology (Other)", value: "148" },
            { name: "Technology (Retro)", value: "107" },
            { name: "Technology (Science)", value: "209" },
            { name: "Technology (Telecommunications)", value: "104" },
            { name: "Travel (Africa)", value: "56" },
            { name: "Travel (America)", value: "58" },
            { name: "Travel (Antarctica)", value: "176" },
            { name: "Travel (Arts & Architecture)", value: "65" },
            { name: "Travel (Asia)", value: "57" },
            { name: "Travel (Australasian)", value: "60" },
            { name: "Travel (Cruise)", value: "62" },
            { name: "Travel (Cuisine)", value: "63" },
            { name: "Travel (Currencies)", value: "67" },
            { name: "Travel (Destination scenics)", value: "61" },
            { name: "Travel (Europe)", value: "59" },
            { name: "Travel (Flags)", value: "68" },
            { name: "Travel (Resorts)", value: "64" },
            { name: "Travel (Tropical)", value: "66" },
            { name: "Web Design Graphics (Banners)", value: "201" },
            { name: "Web Design Graphics (Buttons)", value: "200" },
            {
              name: "Web Design Graphics (Web Backgrounds & Textures)",
              value: "199",
            },
            { name: "Web Design Graphics (Web Icons)", value: "198" },
          ],
        },
        Categoryº2: {
          value: selectedValues[1] || "0",
          options: [
            { name: "None", value: "0" },
            { name: "Abstract (Aerial)", value: "211" },
            { name: "Abstract (Backgrounds)", value: "112" },
            { name: "Abstract (Blurs)", value: "39" },
            { name: "Abstract (Colors)", value: "164" },
            { name: "Abstract (Competition)", value: "40" },
            { name: "Abstract (Craftsmanship)", value: "41" },
            { name: "Abstract (Danger)", value: "42" },
            { name: "Abstract (Exploration)", value: "43" },
            { name: "Abstract (Fun)", value: "158" },
            { name: "Abstract (Help)", value: "44" },
            { name: "Abstract (Love)", value: "149" },
            { name: "Abstract (Luxury)", value: "45" },
            { name: "Abstract (Mobile)", value: "187" },
            { name: "Abstract (Peace)", value: "46" },
            { name: "Abstract (Planetarium)", value: "165" },
            { name: "Abstract (Power)", value: "47" },
            { name: "Abstract (Purity)", value: "48" },
            { name: "Abstract (Religion)", value: "128" },
            { name: "Abstract (Seasonal & Holiday)", value: "155" },
            { name: "Abstract (Security)", value: "49" },
            { name: "Abstract (Sports)", value: "50" },
            { name: "Abstract (Stress)", value: "51" },
            { name: "Abstract (Success)", value: "52" },
            { name: "Abstract (Teamwork)", value: "53" },
            { name: "Abstract (Textures)", value: "141" },
            { name: "Abstract (Unique)", value: "54" },
            { name: "Animals (Birds)", value: "31" },
            { name: "Animals (Farm)", value: "33" },
            { name: "Animals (Insects)", value: "36" },
            { name: "Animals (Mammals)", value: "32" },
            { name: "Animals (Marine life)", value: "34" },
            { name: "Animals (Pets)", value: "30" },
            { name: "Animals (Reptiles & Amphibians)", value: "35" },
            { name: "Animals (Rodents)", value: "37" },
            { name: "Animals (Wildlife)", value: "168" },
            { name: "Arts & Architecture (Details)", value: "124" },
            {
              name: "Arts & Architecture (Generic architecture)",
              value: "71",
            },
            {
              name: "Arts & Architecture (Historic buildings)",
              value: "132",
            },
            { name: "Arts & Architecture (Home)", value: "153" },
            { name: "Arts & Architecture (Indoor)", value: "73" },
            { name: "Arts & Architecture (Landmarks)", value: "70" },
            { name: "Arts & Architecture (Modern buildings)", value: "131" },
            { name: "Arts & Architecture (Night scenes)", value: "130" },
            { name: "Arts & Architecture (Outdoor)", value: "72" },
            { name: "Arts & Architecture (Ruins & Ancient)", value: "174" },
            { name: "Arts & Architecture (Work places)", value: "154" },
            { name: "Business (Communications)", value: "79" },
            { name: "Business (Computers)", value: "78" },
            { name: "Business (Finance)", value: "80" },
            { name: "Business (Industries)", value: "77" },
            { name: "Business (Metaphors)", value: "83" },
            { name: "Business (Objects)", value: "84" },
            { name: "Business (People)", value: "75" },
            { name: "Business (Still-life)", value: "81" },
            { name: "Business (Teams)", value: "76" },
            { name: "Business (Transportation)", value: "82" },
            { name: "Business (Travel)", value: "85" },
            { name: "Editorial (Celebrities)", value: "178" },
            { name: "Editorial (Commercial)", value: "185" },
            { name: "Editorial (Events)", value: "179" },
            { name: "Editorial (Landmarks)", value: "184" },
            { name: "Editorial (People)", value: "180" },
            { name: "Editorial (Politics)", value: "181" },
            { name: "Editorial (Sports)", value: "182" },
            { name: "Editorial (Weather & Environment)", value: "183" },
            { name: "Holidays (Chinese New Year)", value: "204" },
            { name: "Holidays (Christmas)", value: "190" },
            { name: "Holidays (Cinco de Mayo)", value: "207" },
            { name: "Holidays (Diwali)", value: "203" },
            { name: "Holidays (Easter)", value: "193" },
            { name: "Holidays (Fathers Day)", value: "196" },
            { name: "Holidays (Halloween)", value: "192" },
            { name: "Holidays (Hanukkah)", value: "208" },
            { name: "Holidays (Mardi Gras)", value: "206" },
            { name: "Holidays (Mothers Day)", value: "195" },
            { name: "Holidays (New Years)", value: "189" },
            { name: "Holidays (Other)", value: "202" },
            { name: "Holidays (Ramadan)", value: "205" },
            { name: "Holidays (Thanksgiving)", value: "191" },
            { name: "Holidays (Valentines Day)", value: "194" },
            { name: "IT & C (Artificial Intelligence)", value: "210" },
            { name: "IT & C (Connectivity)", value: "110" },
            { name: "IT & C (Equipment)", value: "113" },
            { name: "IT & C (Internet)", value: "111" },
            { name: "IT & C (Networking)", value: "109" },
            { name: "Illustrations & Clipart (AI generated)", value: "212" },
            {
              name: "Illustrations & Clipart (3D & Computer generated)",
              value: "166",
            },
            {
              name: "Illustrations & Clipart (Hand drawn & Artistic)",
              value: "167",
            },
            { name: "Illustrations & Clipart (Illustrations)", value: "163" },
            { name: "Illustrations & Clipart (Vector)", value: "186" },
            { name: "Industries (Agriculture)", value: "101" },
            { name: "Industries (Architecture)", value: "89" },
            { name: "Industries (Banking)", value: "87" },
            { name: "Industries (Cargo & Shipping)", value: "93" },
            { name: "Industries (Communications)", value: "94" },
            { name: "Industries (Computers)", value: "91" },
            { name: "Industries (Construction)", value: "90" },
            { name: "Industries (Education)", value: "150" },
            { name: "Industries (Entertainment)", value: "136" },
            { name: "Industries (Environment)", value: "99" },
            { name: "Industries (Food & Beverages)", value: "127" },
            { name: "Industries (Healthcare & Medical)", value: "92" },
            { name: "Industries (Insurance)", value: "96" },
            { name: "Industries (Legal)", value: "95" },
            { name: "Industries (Manufacturing)", value: "100" },
            { name: "Industries (Military)", value: "102" },
            { name: "Industries (Oil and gas)", value: "161" },
            { name: "Industries (Power and energy)", value: "97" },
            { name: "Industries (Sports)", value: "157" },
            { name: "Industries (Transportation)", value: "98" },
            { name: "Industries (Travel)", value: "88" },
            { name: "Nature (Clouds and skies)", value: "22" },
            { name: "Nature (Deserts)", value: "17" },
            { name: "Nature (Details)", value: "14" },
            { name: "Nature (Fields & Meadows)", value: "27" },
            { name: "Nature (Flowers & Gardens)", value: "25" },
            { name: "Nature (Food ingredients)", value: "28" },
            { name: "Nature (Forests)", value: "18" },
            { name: "Nature (Fruits & Vegetables)", value: "137" },
            { name: "Nature (Generic vegetation)", value: "11" },
            { name: "Nature (Geologic and mineral)", value: "143" },
            { name: "Nature (Lakes and rivers)", value: "16" },
            { name: "Nature (Landscapes)", value: "146" },
            { name: "Nature (Mountains)", value: "15" },
            { name: "Nature (Plants and trees)", value: "12" },
            { name: "Nature (Sea & Ocean)", value: "19" },
            { name: "Nature (Seasons specific)", value: "26" },
            { name: "Nature (Sunsets & Sunrises)", value: "23" },
            { name: "Nature (Tropical)", value: "20" },
            { name: "Nature (Water)", value: "171" },
            { name: "Nature (Waterfalls)", value: "24" },
            { name: "Objects (Clothing & Accessories)", value: "142" },
            { name: "Objects (Electronics)", value: "147" },
            { name: "Objects (Home related)", value: "138" },
            { name: "Objects (Isolated)", value: "135" },
            { name: "Objects (Music and sound)", value: "151" },
            { name: "Objects (Other)", value: "145" },
            { name: "Objects (Retro)", value: "152" },
            { name: "Objects (Sports)", value: "156" },
            { name: "Objects (Still life)", value: "144" },
            { name: "Objects (Tools)", value: "140" },
            { name: "Objects (Toys)", value: "134" },
            { name: "People (Active)", value: "123" },
            { name: "People (Body parts)", value: "139" },
            { name: "People (Children)", value: "119" },
            { name: "People (Cosmetic & Makeup)", value: "175" },
            { name: "People (Couples)", value: "115" },
            { name: "People (Diversity)", value: "122" },
            { name: "People (Expressions)", value: "159" },
            { name: "People (Families)", value: "118" },
            { name: "People (Men)", value: "117" },
            { name: "People (Nudes)", value: "173" },
            { name: "People (Portraits)", value: "162" },
            { name: "People (Seniors)", value: "121" },
            { name: "People (Teens)", value: "120" },
            { name: "People (Women)", value: "116" },
            { name: "People (Workers)", value: "160" },
            { name: "Technology (Computers)", value: "105" },
            { name: "Technology (Connections)", value: "106" },
            { name: "Technology (Electronics)", value: "129" },
            { name: "Technology (Other)", value: "148" },
            { name: "Technology (Retro)", value: "107" },
            { name: "Technology (Science)", value: "209" },
            { name: "Technology (Telecommunications)", value: "104" },
            { name: "Travel (Africa)", value: "56" },
            { name: "Travel (America)", value: "58" },
            { name: "Travel (Antarctica)", value: "176" },
            { name: "Travel (Arts & Architecture)", value: "65" },
            { name: "Travel (Asia)", value: "57" },
            { name: "Travel (Australasian)", value: "60" },
            { name: "Travel (Cruise)", value: "62" },
            { name: "Travel (Cuisine)", value: "63" },
            { name: "Travel (Currencies)", value: "67" },
            { name: "Travel (Destination scenics)", value: "61" },
            { name: "Travel (Europe)", value: "59" },
            { name: "Travel (Flags)", value: "68" },
            { name: "Travel (Resorts)", value: "64" },
            { name: "Travel (Tropical)", value: "66" },
            { name: "Web Design Graphics (Banners)", value: "201" },
            { name: "Web Design Graphics (Buttons)", value: "200" },
            {
              name: "Web Design Graphics (Web Backgrounds & Textures)",
              value: "199",
            },
            { name: "Web Design Graphics (Web Icons)", value: "198" },
          ],
        },
        Categoryº3: {
          value: selectedValues[2] || "0",
          options: [
            { name: "None", value: "0" },
            { name: "Abstract (Aerial)", value: "211" },
            { name: "Abstract (Backgrounds)", value: "112" },
            { name: "Abstract (Blurs)", value: "39" },
            { name: "Abstract (Colors)", value: "164" },
            { name: "Abstract (Competition)", value: "40" },
            { name: "Abstract (Craftsmanship)", value: "41" },
            { name: "Abstract (Danger)", value: "42" },
            { name: "Abstract (Exploration)", value: "43" },
            { name: "Abstract (Fun)", value: "158" },
            { name: "Abstract (Help)", value: "44" },
            { name: "Abstract (Love)", value: "149" },
            { name: "Abstract (Luxury)", value: "45" },
            { name: "Abstract (Mobile)", value: "187" },
            { name: "Abstract (Peace)", value: "46" },
            { name: "Abstract (Planetarium)", value: "165" },
            { name: "Abstract (Power)", value: "47" },
            { name: "Abstract (Purity)", value: "48" },
            { name: "Abstract (Religion)", value: "128" },
            { name: "Abstract (Seasonal & Holiday)", value: "155" },
            { name: "Abstract (Security)", value: "49" },
            { name: "Abstract (Sports)", value: "50" },
            { name: "Abstract (Stress)", value: "51" },
            { name: "Abstract (Success)", value: "52" },
            { name: "Abstract (Teamwork)", value: "53" },
            { name: "Abstract (Textures)", value: "141" },
            { name: "Abstract (Unique)", value: "54" },
            { name: "Animals (Birds)", value: "31" },
            { name: "Animals (Farm)", value: "33" },
            { name: "Animals (Insects)", value: "36" },
            { name: "Animals (Mammals)", value: "32" },
            { name: "Animals (Marine life)", value: "34" },
            { name: "Animals (Pets)", value: "30" },
            { name: "Animals (Reptiles & Amphibians)", value: "35" },
            { name: "Animals (Rodents)", value: "37" },
            { name: "Animals (Wildlife)", value: "168" },
            { name: "Arts & Architecture (Details)", value: "124" },
            {
              name: "Arts & Architecture (Generic architecture)",
              value: "71",
            },
            {
              name: "Arts & Architecture (Historic buildings)",
              value: "132",
            },
            { name: "Arts & Architecture (Home)", value: "153" },
            { name: "Arts & Architecture (Indoor)", value: "73" },
            { name: "Arts & Architecture (Landmarks)", value: "70" },
            { name: "Arts & Architecture (Modern buildings)", value: "131" },
            { name: "Arts & Architecture (Night scenes)", value: "130" },
            { name: "Arts & Architecture (Outdoor)", value: "72" },
            { name: "Arts & Architecture (Ruins & Ancient)", value: "174" },
            { name: "Arts & Architecture (Work places)", value: "154" },
            { name: "Business (Communications)", value: "79" },
            { name: "Business (Computers)", value: "78" },
            { name: "Business (Finance)", value: "80" },
            { name: "Business (Industries)", value: "77" },
            { name: "Business (Metaphors)", value: "83" },
            { name: "Business (Objects)", value: "84" },
            { name: "Business (People)", value: "75" },
            { name: "Business (Still-life)", value: "81" },
            { name: "Business (Teams)", value: "76" },
            { name: "Business (Transportation)", value: "82" },
            { name: "Business (Travel)", value: "85" },
            { name: "Editorial (Celebrities)", value: "178" },
            { name: "Editorial (Commercial)", value: "185" },
            { name: "Editorial (Events)", value: "179" },
            { name: "Editorial (Landmarks)", value: "184" },
            { name: "Editorial (People)", value: "180" },
            { name: "Editorial (Politics)", value: "181" },
            { name: "Editorial (Sports)", value: "182" },
            { name: "Editorial (Weather & Environment)", value: "183" },
            { name: "Holidays (Chinese New Year)", value: "204" },
            { name: "Holidays (Christmas)", value: "190" },
            { name: "Holidays (Cinco de Mayo)", value: "207" },
            { name: "Holidays (Diwali)", value: "203" },
            { name: "Holidays (Easter)", value: "193" },
            { name: "Holidays (Fathers Day)", value: "196" },
            { name: "Holidays (Halloween)", value: "192" },
            { name: "Holidays (Hanukkah)", value: "208" },
            { name: "Holidays (Mardi Gras)", value: "206" },
            { name: "Holidays (Mothers Day)", value: "195" },
            { name: "Holidays (New Years)", value: "189" },
            { name: "Holidays (Other)", value: "202" },
            { name: "Holidays (Ramadan)", value: "205" },
            { name: "Holidays (Thanksgiving)", value: "191" },
            { name: "Holidays (Valentines Day)", value: "194" },
            { name: "IT & C (Artificial Intelligence)", value: "210" },
            { name: "IT & C (Connectivity)", value: "110" },
            { name: "IT & C (Equipment)", value: "113" },
            { name: "IT & C (Internet)", value: "111" },
            { name: "IT & C (Networking)", value: "109" },
            { name: "Illustrations & Clipart (AI generated)", value: "212" },
            {
              name: "Illustrations & Clipart (3D & Computer generated)",
              value: "166",
            },
            {
              name: "Illustrations & Clipart (Hand drawn & Artistic)",
              value: "167",
            },
            { name: "Illustrations & Clipart (Illustrations)", value: "163" },
            { name: "Illustrations & Clipart (Vector)", value: "186" },
            { name: "Industries (Agriculture)", value: "101" },
            { name: "Industries (Architecture)", value: "89" },
            { name: "Industries (Banking)", value: "87" },
            { name: "Industries (Cargo & Shipping)", value: "93" },
            { name: "Industries (Communications)", value: "94" },
            { name: "Industries (Computers)", value: "91" },
            { name: "Industries (Construction)", value: "90" },
            { name: "Industries (Education)", value: "150" },
            { name: "Industries (Entertainment)", value: "136" },
            { name: "Industries (Environment)", value: "99" },
            { name: "Industries (Food & Beverages)", value: "127" },
            { name: "Industries (Healthcare & Medical)", value: "92" },
            { name: "Industries (Insurance)", value: "96" },
            { name: "Industries (Legal)", value: "95" },
            { name: "Industries (Manufacturing)", value: "100" },
            { name: "Industries (Military)", value: "102" },
            { name: "Industries (Oil and gas)", value: "161" },
            { name: "Industries (Power and energy)", value: "97" },
            { name: "Industries (Sports)", value: "157" },
            { name: "Industries (Transportation)", value: "98" },
            { name: "Industries (Travel)", value: "88" },
            { name: "Nature (Clouds and skies)", value: "22" },
            { name: "Nature (Deserts)", value: "17" },
            { name: "Nature (Details)", value: "14" },
            { name: "Nature (Fields & Meadows)", value: "27" },
            { name: "Nature (Flowers & Gardens)", value: "25" },
            { name: "Nature (Food ingredients)", value: "28" },
            { name: "Nature (Forests)", value: "18" },
            { name: "Nature (Fruits & Vegetables)", value: "137" },
            { name: "Nature (Generic vegetation)", value: "11" },
            { name: "Nature (Geologic and mineral)", value: "143" },
            { name: "Nature (Lakes and rivers)", value: "16" },
            { name: "Nature (Landscapes)", value: "146" },
            { name: "Nature (Mountains)", value: "15" },
            { name: "Nature (Plants and trees)", value: "12" },
            { name: "Nature (Sea & Ocean)", value: "19" },
            { name: "Nature (Seasons specific)", value: "26" },
            { name: "Nature (Sunsets & Sunrises)", value: "23" },
            { name: "Nature (Tropical)", value: "20" },
            { name: "Nature (Water)", value: "171" },
            { name: "Nature (Waterfalls)", value: "24" },
            { name: "Objects (Clothing & Accessories)", value: "142" },
            { name: "Objects (Electronics)", value: "147" },
            { name: "Objects (Home related)", value: "138" },
            { name: "Objects (Isolated)", value: "135" },
            { name: "Objects (Music and sound)", value: "151" },
            { name: "Objects (Other)", value: "145" },
            { name: "Objects (Retro)", value: "152" },
            { name: "Objects (Sports)", value: "156" },
            { name: "Objects (Still life)", value: "144" },
            { name: "Objects (Tools)", value: "140" },
            { name: "Objects (Toys)", value: "134" },
            { name: "People (Active)", value: "123" },
            { name: "People (Body parts)", value: "139" },
            { name: "People (Children)", value: "119" },
            { name: "People (Cosmetic & Makeup)", value: "175" },
            { name: "People (Couples)", value: "115" },
            { name: "People (Diversity)", value: "122" },
            { name: "People (Expressions)", value: "159" },
            { name: "People (Families)", value: "118" },
            { name: "People (Men)", value: "117" },
            { name: "People (Nudes)", value: "173" },
            { name: "People (Portraits)", value: "162" },
            { name: "People (Seniors)", value: "121" },
            { name: "People (Teens)", value: "120" },
            { name: "People (Women)", value: "116" },
            { name: "People (Workers)", value: "160" },
            { name: "Technology (Computers)", value: "105" },
            { name: "Technology (Connections)", value: "106" },
            { name: "Technology (Electronics)", value: "129" },
            { name: "Technology (Other)", value: "148" },
            { name: "Technology (Retro)", value: "107" },
            { name: "Technology (Science)", value: "209" },
            { name: "Technology (Telecommunications)", value: "104" },
            { name: "Travel (Africa)", value: "56" },
            { name: "Travel (America)", value: "58" },
            { name: "Travel (Antarctica)", value: "176" },
            { name: "Travel (Arts & Architecture)", value: "65" },
            { name: "Travel (Asia)", value: "57" },
            { name: "Travel (Australasian)", value: "60" },
            { name: "Travel (Cruise)", value: "62" },
            { name: "Travel (Cuisine)", value: "63" },
            { name: "Travel (Currencies)", value: "67" },
            { name: "Travel (Destination scenics)", value: "61" },
            { name: "Travel (Europe)", value: "59" },
            { name: "Travel (Flags)", value: "68" },
            { name: "Travel (Resorts)", value: "64" },
            { name: "Travel (Tropical)", value: "66" },
            { name: "Web Design Graphics (Banners)", value: "201" },
            { name: "Web Design Graphics (Buttons)", value: "200" },
            {
              name: "Web Design Graphics (Web Backgrounds & Textures)",
              value: "199",
            },
            { name: "Web Design Graphics (Web Icons)", value: "198" },
          ],
        },
        keywords: { value: "keywords" },
        W$EL: {
          value: selectedValues[3] || "0",
          options: [
            { name: "No", value: "0" },
            { name: "Yes", value: "1" },
          ],
        },
        SR$EL: {
          value: selectedValues[4] || "0",
          options: [
            { name: "No", value: "0" },
            { name: "Yes", value: "1" },
          ],
        },
        SR$Price: {
          value: selectedValues[5] || "0",
          options: [
            { name: "No", value: "0" },
            { name: "Yes", value: "1" },
          ],
        },
        Editorial: {
          value: selectedValues[6] || "0",
          options: [
            { name: "No", value: "0" },
            { name: "Yes", value: "1" },
          ],
        },
        MRºdocºIds: {
          value: selectedValues[7] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        PrºDocs: {
          value: selectedValues[8] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
      },
      options: {
        semicolon: false,
      },
    },
    motionelements: {
      fields: {
        ElementºID: { value: "name" },
        Title: { value: "title" },
        Price: {
          value: selectedValues[0] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        Category: {
          value: selectedValues[1] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        Description: { value: "description" },
        IsºAnimation: {
          value: selectedValues[2] || "No",
          options: [
            { name: "No", value: "No" },
            { name: "Yes", value: "Yes" },
          ],
        },
        Keywords: { value: "keywords" },
        DateºCreated: {
          value: selectedValues[3] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        Source: {
          value: selectedValues[4] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        Creator: {
          value: selectedValues[5] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        HasºAlphaºMatte: {
          value: selectedValues[6] || "No",
          options: [
            { name: "No", value: "No" },
            { name: "Yes", value: "Yes" },
          ],
        },
        Framing: {
          value: selectedValues[7] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        Speed: {
          value: selectedValues[8] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        Loopable: {
          value: selectedValues[9] || "No",
          options: [
            { name: "No", value: "No" },
            { name: "Yes", value: "Yes" },
          ],
        },
        Country: {
          value: selectedValues[10] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        Place: {
          value: selectedValues[11] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        ModelºRelease: {
          value: selectedValues[12] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        PropertyºRelease: {
          value: selectedValues[13] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        License: {
          value: selectedValues[14] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        Publish: {
          value: selectedValues[15] || "No",
          options: [
            { name: "No", value: "No" },
            { name: "Yes", value: "Yes" },
          ],
        },
        Comment: {
          value: selectedValues[16] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
      },
      options: {
        semicolon: false,
      },
    },
    motionelements_video: {
      fields: {
        ElementºID: { value: "name" },
        Title: { value: "title" },
        Price: {
          value: selectedValues[0] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        Category1: {
          value: selectedValues[1] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        Category2: {
          value: selectedValues[2] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        Description: { value: "description" },
        Keywords: { value: "keywords" },
        MediaºType: {
          value: selectedValues[3] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        AlphaºMatte: {
          value: selectedValues[4] || "No",
          options: [
            { name: "No", value: "No" },
            { name: "Yes", value: "Yes" },
          ],
        },
        Loopable: {
          value: selectedValues[5] || "No",
          options: [
            { name: "No", value: "No" },
            { name: "Yes", value: "Yes" },
          ],
        },
        Framing: {
          value: selectedValues[6] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        Speed: {
          value: selectedValues[7] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        Place: {
          value: selectedValues[8] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        City: {
          value: selectedValues[9] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        RegionºStateºProvince: {
          value: selectedValues[10] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        Country: {
          value: selectedValues[11] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        Source: {
          value: selectedValues[12] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        SourceºDateºCreated: {
          value: selectedValues[13] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        Rendering: {
          value: selectedValues[14] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        TalentºRelease: {
          value: selectedValues[15] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        PropertyºRelease: {
          value: selectedValues[16] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
      },
      options: {
        semicolon: false,
      },
    },
    envato: {
      fields: {
        Filename: { value: "name" },
        Title: { value: "title" },
        Description: { value: "description" },
        Keywords: { value: "keywords" },
      },
      options: {
        semicolon: false,
      },
    },
    yayimages: {
      fields: {
        original_filename: { value: "name" },
        title: { value: "title" },
        description: { value: "description" },
        keywords: { value: "keywords" },
      },
      options: {
        semicolon: false,
      },
    },
    story_blocks: {
      fields: {
        Filename: { value: "name" },
        Title: { value: "title" },
        Description: { value: "description" },
        Keywords: { value: "keywords" },
      },
      options: {
        semicolon: false,
      },
    },
    pixta: {
      fields: {
        Fileºname: { value: "name" },
        Title: { value: "title" },
        Keyword: { value: "keywords" },
      },
      options: {
        semicolon: false,
      },
    },
    stocksubmitter: {
      fields: {
        Filename: { value: "name" },
        Title: { value: "title" },
        Description: { value: "description" },
        Keywords: { value: "keywords" },
        Category: {
          value: selectedValues[0] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        Releaseºname: {
          value: selectedValues[1] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
      },
      options: {
        semicolon: false,
      },
    },
    microstockplus: {
      fields: {
        filename: { value: "name" },
        title: { value: "title" },
        description: { value: "description" },
        keywords: { value: "keywords" },
      },
      options: {
        semicolon: false,
      },
    },
    keycut_stock: {
      fields: {
        Filename: { value: "name" },
        Title: { value: "title" },
        Description: { value: "description" },
        Keywords: { value: "keywords" },
      },
      options: {
        semicolon: false,
      },
    },
    vecteezy: {
      fields: {
        Filename: { value: "name" },
        Title: { value: "title" },
        Description: { value: "description" },
        Keywords: { value: "keywords" },
        License: {
          value: selectedValues[0] || "",
          options: [
            { name: "Free", value: "free" },
            { name: "Pro", value: "pro" },
            { name: "Editorial", value: "editorial" },
          ],
        },
      },
      options: {
        semicolon: false,
      },
    },
    rf123: {
      fields: {
        oldfilename: { value: "name" },
        "123rf_filename": {
          value: selectedValues[0] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        description: { value: "title" },
        keywords: { value: "keywords" },
        country: {
          value: selectedValues[1] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
      },
      options: {
        semicolon: false,
      },
    },
    blackbox: {
      fields: {
        FileºName: { value: "name" },
        Description: { value: "title" },
        Keywords: { value: "keywords" },
        Category: {
          value: selectedValues[0] || "",
          options: [
            { name: "None", value: " " },
            { name: "Animals", value: "Animals" },
            { name: "Objects & Graphics", value: "Objects & Graphics" },
            { name: "Arts & Entertainment", value: "Arts & Entertainment" },
            { name: "Beauty & Health", value: "Beauty & Health" },
            { name: "Business", value: "Business" },
            { name: "Food", value: "Food" },
            { name: "Drink", value: "Drink" },
            { name: "Industry", value: "Industry" },
            { name: "Location & Buildings", value: "Location & Buildings" },
            { name: "Medical", value: "Medical" },
            { name: "Nature", value: "Nature" },
            { name: "Objects & Equipment", value: "Objects & Equipment" },
            { name: "People", value: "People" },
            { name: "Religion", value: "Religion" },
            { name: "Science", value: "Science" },
            { name: "Sport & Fitness", value: "Sport & Fitness" },
            { name: "Technology", value: "Technology" },
            { name: "Time Lapse", value: "Time Lapse" },
            { name: "Transportation", value: "Transportation" },
            { name: "Travel", value: "Travel" },
          ],
        },
        Batchºname: {
          value: "",
        },
        Editorial: {
          value: selectedValues[1] || "FALSE",
          options: [
            { name: "FALSE", value: "FALSE" },
            { name: "TRUE", value: "TRUE" },
          ],
        },
        EditorialºText: {
          value: selectedValues[2] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        EditorialºCity: {
          value: selectedValues[3] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        EditorialºState: {
          value: selectedValues[4] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        EditorialºCountry: {
          value: selectedValues[5] || "",
          options: [
            { name: "Select country", value: " " },
            { name: "Afghanistan", value: "Afghanistan" },
            { name: "Albania", value: "Albania" },
            { name: "Algeria", value: "Algeria" },
            { name: "Andorra", value: "Andorra" },
            { name: "Angola", value: "Angola" },
            { name: "Anguilla (UK)", value: "Anguilla (UK)" },
            { name: "Antigua and Barbuda", value: "Antigua and Barbuda" },
            { name: "Argentina", value: "Argentina" },
            { name: "Armenia", value: "Armenia" },
            { name: "Aruba (Netherlands)", value: "Aruba (Netherlands)" },
            { name: "Australia", value: "Australia" },
            { name: "Austria", value: "Austria" },
            { name: "Azerbaijan", value: "Azerbaijan" },
            { name: "Bahamas", value: "Bahamas" },
            { name: "Bahrain", value: "Bahrain" },
            { name: "Bangladesh", value: "Bangladesh" },
            { name: "Barbados", value: "Barbados" },
            { name: "Belarus", value: "Belarus" },
            { name: "Belgium", value: "Belgium" },
            { name: "Belize", value: "Belize" },
            { name: "Benin", value: "Benin" },
            { name: "Bhutan", value: "Bhutan" },
            { name: "Bolivia", value: "Bolivia" },
            {
              name: "Bosnia and Herzegovina",
              value: "Bosnia and Herzegovina",
            },
            { name: "Botswana", value: "Botswana" },
            { name: "Brazil", value: "Brazil" },
            {
              name: "British Virgin Islands (UK)",
              value: "British Virgin Islands (UK)",
            },
            { name: "Brunei", value: "Brunei" },
            { name: "Bulgaria", value: "Bulgaria" },
            { name: "Burkina Faso", value: "Burkina Faso" },
            { name: "Burundi", value: "Burundi" },
            { name: "Cabo Verde", value: "Cabo Verde" },
            { name: "Cambodia", value: "Cambodia" },
            { name: "Cameroon", value: "Cameroon" },
            { name: "Canada", value: "Canada" },
            {
              name: "Caribbean Netherlands (Netherlands)",
              value: "Caribbean Netherlands (Netherlands)",
            },
            { name: "Cayman Islands (UK)", value: "Cayman Islands (UK)" },
            {
              name: "Central African Republic",
              value: "Central African Republic",
            },
            { name: "Chad", value: "Chad" },
            { name: "Chile", value: "Chile" },
            { name: "China", value: "China" },
            { name: "Colombia", value: "Colombia" },
            { name: "Comoros", value: "Comoros" },
            { name: "Congo", value: "Congo" },
            { name: "Costa Rica", value: "Costa Rica" },
            { name: "Cote d'Ivoire", value: "Cote d'Ivoire" },
            { name: "Croatia", value: "Croatia" },
            { name: "Cuba", value: "Cuba" },
            { name: "Curaçao", value: "Curaçao" },
            { name: "Cyprus", value: "Cyprus" },
            { name: "Czech Republic", value: "Czech Republic" },
            { name: "Denmark", value: "Denmark" },
            { name: "Djibouti", value: "Djibouti" },
            { name: "Dominica", value: "Dominica" },
            { name: "Dominican Republic", value: "Dominican Republic" },
            { name: "Ecuador", value: "Ecuador" },
            { name: "Egypt", value: "Egypt" },
            { name: "El Salvador", value: "El Salvador" },
            { name: "Equatorial Guinea", value: "Equatorial Guinea" },
            { name: "Eritrea", value: "Eritrea" },
            { name: "Estonia", value: "Estonia" },
            { name: "Ethiopia", value: "Ethiopia" },
            { name: "Fiji", value: "Fiji" },
            { name: "Finland", value: "Finland" },
            { name: "France", value: "France" },
            { name: "Gabon", value: "Gabon" },
            { name: "Gambia", value: "Gambia" },
            { name: "Georgia", value: "Georgia" },
            { name: "Germany", value: "Germany" },
            { name: "Ghana", value: "Ghana" },
            { name: "Greece", value: "Greece" },
            { name: "Grenada", value: "Grenada" },
            { name: "Guadeloupe (France)", value: "Guadeloupe (France)" },
            { name: "Guatemala", value: "Guatemala" },
            { name: "Guinea", value: "Guinea" },
            { name: "Guinea-Bissau", value: "Guinea-Bissau" },
            { name: "Guyana", value: "Guyana" },
            { name: "Haiti", value: "Haiti" },
            { name: "Honduras", value: "Honduras" },
            { name: "Hungary", value: "Hungary" },
            { name: "Iceland", value: "Iceland" },
            { name: "India", value: "India" },
            { name: "Indonesia", value: "Indonesia" },
            { name: "Iran", value: "Iran" },
            { name: "Iraq", value: "Iraq" },
            { name: "Ireland", value: "Ireland" },
            { name: "Israel", value: "Israel" },
            { name: "Italy", value: "Italy" },
            { name: "Jamaica", value: "Jamaica" },
            { name: "Japan", value: "Japan" },
            { name: "Jordan", value: "Jordan" },
            { name: "Kazakhstan", value: "Kazakhstan" },
            { name: "Kenya", value: "Kenya" },
            { name: "Kiribati", value: "Kiribati" },
            { name: "Kosovo", value: "Kosovo" },
            { name: "Kuwait", value: "Kuwait" },
            { name: "Kyrgyzstan", value: "Kyrgyzstan" },
            { name: "Laos", value: "Laos" },
            { name: "Latvia", value: "Latvia" },
            { name: "Lebanon", value: "Lebanon" },
            { name: "Lesotho", value: "Lesotho" },
            { name: "Liberia", value: "Liberia" },
            { name: "Libya", value: "Libya" },
            { name: "Liechtenstein", value: "Liechtenstein" },
            { name: "Lithuania", value: "Lithuania" },
            { name: "Luxembourg", value: "Luxembourg" },
            { name: "Macedonia", value: "Macedonia" },
            { name: "Madagascar", value: "Madagascar" },
            { name: "Malawi", value: "Malawi" },
            { name: "Malaysia", value: "Malaysia" },
            { name: "Maldives", value: "Maldives" },
            { name: "Mali", value: "Mali" },
            { name: "Malta", value: "Malta" },
            { name: "Marshall Islands", value: "Marshall Islands" },
            { name: "Martinique (France)", value: "Martinique (France)" },
            { name: "Mauritania", value: "Mauritania" },
            { name: "Mauritius", value: "Mauritius" },
            { name: "Mexico", value: "Mexico" },
            { name: "Micronesia", value: "Micronesia" },
            { name: "Moldova", value: "Moldova" },
            { name: "Monaco", value: "Monaco" },
            { name: "Mongolia", value: "Mongolia" },
            { name: "Montenegro", value: "Montenegro" },
            { name: "Montserrat (UK)", value: "Montserrat (UK)" },
            { name: "Morocco", value: "Morocco" },
            { name: "Mozambique", value: "Mozambique" },
            { name: "Myanmar", value: "Myanmar" },
            { name: "Namibia", value: "Namibia" },
            { name: "Nauru", value: "Nauru" },
            { name: "Nepal", value: "Nepal" },
            { name: "Netherlands", value: "Netherlands" },
            { name: "New Zealand", value: "New Zealand" },
            { name: "Nicaragua", value: "Nicaragua" },
            { name: "Niger", value: "Niger" },
            { name: "Nigeria", value: "Nigeria" },
            { name: "North Korea", value: "North Korea" },
            { name: "Norway", value: "Norway" },
            { name: "Oman", value: "Oman" },
            { name: "Pakistan", value: "Pakistan" },
            { name: "Palau", value: "Palau" },
            { name: "Palestine", value: "Palestine" },
            { name: "Panama", value: "Panama" },
            { name: "Papua New Guinea", value: "Papua New Guinea" },
            { name: "Paraguay", value: "Paraguay" },
            { name: "Peru", value: "Peru" },
            { name: "Philippines", value: "Philippines" },
            { name: "Poland", value: "Poland" },
            { name: "Portugal", value: "Portugal" },
            { name: "Puerto Rico (US)", value: "Puerto Rico (US)" },
            { name: "Qatar", value: "Qatar" },
            { name: "Romania", value: "Romania" },
            { name: "Russia", value: "Russia" },
            { name: "Rwanda", value: "Rwanda" },
            {
              name: "Saint Barthélemy (France)",
              value: "Saint Barthélemy (France)",
            },
            { name: "Saint Martin (France)", value: "Saint Martin (France)" },
            { name: "Samoa", value: "Samoa" },
            { name: "San Marino", value: "San Marino" },
            { name: "Sao Tome and Principe", value: "Sao Tome and Principe" },
            { name: "Saudi Arabia", value: "Saudi Arabia" },
            { name: "Senegal", value: "Senegal" },
            { name: "Serbia", value: "Serbia" },
            { name: "Seychelles", value: "Seychelles" },
            { name: "Sierra Leone", value: "Sierra Leone" },
            { name: "Singapore", value: "Singapore" },
            {
              name: "Sint Maarten (Netherlands)",
              value: "Sint Maarten (Netherlands)",
            },
            { name: "Slovakia", value: "Slovakia" },
            { name: "Slovenia", value: "Slovenia" },
            { name: "Solomon Islands", value: "Solomon Islands" },
            { name: "Somalia", value: "Somalia" },
            { name: "South Africa", value: "South Africa" },
            { name: "South Korea", value: "South Korea" },
            { name: "South Sudan", value: "South Sudan" },
            { name: "Spain", value: "Spain" },
            { name: "Sri Lanka", value: "Sri Lanka" },
            { name: "St. Kitts and Nevis", value: "St. Kitts and Nevis" },
            { name: "St. Lucia", value: "St. Lucia" },
            {
              name: "St. Vincent and The Grenadines",
              value: "St. Vincent and The Grenadines",
            },
            { name: "Sudan", value: "Sudan" },
            { name: "Suriname", value: "Suriname" },
            { name: "Swaziland", value: "Swaziland" },
            { name: "Sweden", value: "Sweden" },
            { name: "Switzerland", value: "Switzerland" },
            { name: "Syria", value: "Syria" },
            { name: "Taiwan", value: "Taiwan" },
            { name: "Tajikistan", value: "Tajikistan" },
            { name: "Tanzania", value: "Tanzania" },
            { name: "Thailand", value: "Thailand" },
            { name: "Timor-Leste", value: "Timor-Leste" },
            { name: "Togo", value: "Togo" },
            { name: "Tonga", value: "Tonga" },
            { name: "Trinidad and Tobago", value: "Trinidad and Tobago" },
            { name: "Tunisia", value: "Tunisia" },
            { name: "Turkey", value: "Turkey" },
            { name: "Turkmenistan", value: "Turkmenistan" },
            {
              name: "Turks and Caicos Islands (UK)",
              value: "Turks and Caicos Islands (UK)",
            },
            { name: "Tuvalu", value: "Tuvalu" },
            { name: "Uganda", value: "Uganda" },
            { name: "Ukraine", value: "Ukraine" },
            { name: "United Arab Emirates", value: "United Arab Emirates" },
            { name: "United Kingdom (UK)", value: "United Kingdom (UK)" },
            {
              name: "United States of America (USA)",
              value: "United States of America (USA)",
            },
            {
              name: "United States Virgin Islands (US)",
              value: "United States Virgin Islands (US)",
            },
            { name: "Uruguay", value: "Uruguay" },
            { name: "Uzbekistan", value: "Uzbekistan" },
            { name: "Vanuatu", value: "Vanuatu" },
            {
              name: "Vatican City (Holy See)",
              value: "Vatican City (Holy See)",
            },
            { name: "Venezuela", value: "Venezuela" },
            { name: "Vietnam", value: "Vietnam" },
            { name: "Yemen", value: "Yemen" },
            { name: "Zambia", value: "Zambia" },
            { name: "Zimbabwe", value: "Zimbabwe" },
          ],
        },
        EditorialºDate: {
          value: selectedValues[6] || "",
          options: [{ name: "Enter custom (MM DD YYYY)", value: " " }],
        },
        Title: {
          value: selectedValues[7] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        ShootingºLocation: {
          value: selectedValues[8] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        ShootingºDate: {
          value: selectedValues[9] || "",
          options: [{ name: "Enter custom (MM DD YYYY)", value: " " }],
        },
      },
      options: {
        semicolon: false,
      },
    },
    motion_array: {
      fields: {
        metadata_csv_version: { value: "2" },
        category: {
          value: selectedValues[0] || "",
          options: [
            { name: "Video", value: "stock-video" },
            { name: "Photo", value: "stock-photo" },
          ],
        },
        filename: { value: "name" },
        title: { value: "title" },
        description: { value: "description" },
        keywords: { value: "keywords" },
        model_release: {
          value: selectedValues[1] || "",
          options: [{ name: "Enter custom", value: " " }],
        },
        editorial_use: {
          value: selectedValues[2] || "",
          options: [
            { name: "No", value: " " },
            { name: "Yes", value: "yes" },
          ],
        },
        sub_categories: {
          value: selectedValues[3] || "",
          options: [
            { name: "Overlay", value: "Overlay" },
            { name: "Background", value: "Background" },
            { name: "People", value: "People" },
            { name: "Technology", value: "Technology" },
            { name: "Green", value: "Green" },
            { name: "Business", value: "Business" },
            { name: "Nature", value: "Nature" },
            { name: "Buildings", value: "Buildings" },
            { name: "Health", value: "Health" },
            { name: "Fashion", value: "Fashion" },
            { name: "Animals", value: "Animals" },
            { name: "Food", value: "Food" },
            { name: "Transportation", value: "Transportation" },
            { name: "Sports", value: "Sports" },
            { name: "Aerial", value: "Aerial" },
            { name: "Holidays", value: "Holidays" },
            { name: "Industrial", value: "Industrial" },
            { name: "Household", value: "Household" },
            { name: "Science", value: "Science" },
            { name: "Education", value: "Education" },
            { name: "Travel", value: "Travel" },
            { name: "Animation", value: "Animation" },
          ],
        },
        has_people: {
          value: selectedValues[4] || "",
          options: [
            { name: "No", value: " " },
            { name: "Yes", value: "yes" },
          ],
        },
      },
      options: {
        semicolon: false,
      },
    },
  };

  return formats?.[formatName];
};
