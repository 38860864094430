import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { NavbarContext } from "../context/NavbarContext";
import { SessionContext } from "../context/SessionContext";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ShapeLineIcon from "@mui/icons-material/ShapeLine";
import BrushIcon from "@mui/icons-material/Brush";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Chip from "@mui/material/Chip";
import { useTranslation } from "react-i18next";

function LandingExamples() {
  const { t } = useTranslation();
  const navbar = useContext(NavbarContext);
  const session = useContext(SessionContext);

  const examples = [
    {
      file: "illustration2.webp",
      title: "A Desert Landscape With Cactus Trees and a Winding Road",
      keywords: ["desert", "cactus", "road", "landscape", "nature"],
      type: "illustration",
    },
    {
      file: "vector5.webp",
      title: "A Juicy Watermelon Slice on a Dark Background",
      keywords: ["watermelon", "fruit", "slice", "juicy", "refreshing"],
      type: "vector",
    },
    {
      file: "ai10.webp",
      title: "Penguins Riding a Motorcycle in a Snowy Landscape",
      keywords: ["penguins", "motorcycle", "ride", "snowy", "landscape"],
      type: "ai",
    },
    {
      file: "vector7.webp",
      title: "A Green Plant in a Red Pot Isolated on aq White Background",
      keywords: [
        "green plant",
        "red pot",
        "isolated",
        "white background",
        "nature",
      ],
      type: "vector",
    },
    {
      file: "vector3.webp",
      title: "A Rubik's Cube With Colorful and Unique Patterns on Each Side",
      keywords: ["Rubik's Cube", "puzzle", "game", "colors", "shapes"],
      type: "vector",
    },
    {
      file: "ai5.webp",
      title: "A Canine Standing Confidently in the Center of a City Street",
      keywords: ["dog", "street", "painting", "art", "illustration"],
      type: "ai",
    },
    {
      file: "photo2.webp",
      title: "A Woman Looking at the Sunset While Sitting in a Car",
      keywords: ["woman", "car", "flower", "nature", "spring"],
      type: "photo",
    },
    {
      file: "photo7.webp",
      title: "A Woman in a Vibrant Dress Seated on a Stool",
      keywords: ["woman", "dress", "colorful", "stool", "seated"],
      type: "photo",
    },
    {
      file: "illustration5.webp",
      title: "A Cartoon Airplane With a Star Emblem on Its Side",
      keywords: ["airplane", "cartoon", "star", "emblem", "aviation"],
      type: "illustration",
    },
    {
      file: "photo4.webp",
      title:
        "A Man With Blue Hair Wearing a White Shirt Against a Plain Background",
      keywords: ["man", "blue hair", "white shirt", "fashion", "style"],
      type: "photo",
    },
    {
      file: "ai15.webp",
      title: "Cats Lounging on a Wooden Floor",
      keywords: ["cats", "feline", "pets", "animals", "domestic"],
      type: "ai",
    },
    {
      file: "ai19.webp",
      title: "A Female Wearing a High-Tech Suit and Headphones",
      keywords: ["future", "technology", "headphones", "audio", "futuristic"],
      type: "ai",
    },
    {
      file: "ai14.webp",
      title: "A Woman Sitting on Top of a Lion Statue in a Park",
      keywords: ["woman", "lion statue", "park", "sculpture", "art"],
      type: "ai",
    },
    {
      file: "illustration9.webp",
      title: "Three Women Enjoying a Swing in a Colorful Garden",
      keywords: ["painting", "art", "women", "swing", "garden"],
      type: "illustration",
    },
    {
      file: "ai17.webp",
      title: "A Skeleton King Sitting on a Royal Throne",
      keywords: ["skeleton", "king", "throne", "royalty", "death"],
      type: "ai",
    },
    {
      file: "photo3.webp",
      title: "A Decorative Mirror and Vase on a Fireplace",
      keywords: ["fireplace", "mirror", "vase", "cozy", "home decor"],
      type: "photo",
    },
    {
      file: "photo19.webp",
      title: "Two Women Lying Side By Side and Enjoying a Sunny Day Outdoors",
      keywords: ["women", "female", "friends", "companionship", "leisure"],
      type: "photo",
    },
    {
      file: "ai13.webp",
      title:
        "A Fantastical Creature With Vibrant Purple Hair and Piercing Blue Eyes",
      keywords: ["creature", "fantasy", "purple hair", "blue eyes", "mythical"],
      type: "ai",
    },
    {
      file: "illustration6.webp",
      title: "A Happy Dog Playing in a Green Field Under a Blue Sky",
      keywords: ["cartoon", "dog", "animal", "pet", "playful"],
      type: "illustration",
    },
    {
      file: "ai21.webp",
      title:
        "A Man Slicing a Large Pizza With a Sharp Knife on a Wooden Cutting Board",
      keywords: ["pizza", "man", "knife", "slice", "food"],
      type: "ai",
    },
    {
      file: "photo6.webp",
      title: "A Construction Worker on a Scaffold in Front of a Building",
      keywords: [
        "construction",
        "worker",
        "scaffold",
        "building",
        "architecture",
      ],
      type: "photo",
    },
    {
      file: "photo1.webp",
      title: "A Couple Enjoying the Panoramic View from a Rooftop",
      keywords: ["couple", "people", "rooftop", "cityscape", "skyline"],
      type: "photo",
    },
    {
      file: "ai4.webp",
      title: "A Castle Suspended in the Sky",
      keywords: ["AI", "painting", "castle", "sky", "floating"],
      type: "ai",
    },
    {
      file: "photo12.webp",
      title: "Men Working on a Construction Site at a High Elevation",
      keywords: ["construction", "site", "workers", "men", "safety"],
      type: "photo",
    },
    {
      file: "photo11.webp",
      title: "A Vintage Race Car Parked in a Lot",
      keywords: ["vintage", "car", "race", "parking lot", "classic"],
      type: "photo",
    },
    {
      file: "photo8.webp",
      title: "A Woman Standing Elegantly in Front of a Sparkling Blue Pool",
      keywords: ["woman", "pool", "swimming", "elegant", "summer"],
      type: "photo",
    },
    {
      file: "vector9.webp",
      title: "A Ripe Red Strawberry Isolated on a Dark Background",
      keywords: ["strawberry", "fruit", "red", "ripe", "sweet"],
      type: "vector",
    },
    {
      file: "photo21.webp",
      title: "Two Monkeys Perched on a Tree Branch",
      keywords: ["monkeys", "tree", "wildlife", "nature", "jungle"],
      type: "photo",
    },
    {
      file: "ai12.webp",
      title: "Two Futuristic Robots Sharing an Umbrella in the Rain",
      keywords: ["robots", "umbrella", "rain", "futuristic", "technology"],
      type: "ai",
    },
    {
      file: "ai11.webp",
      title: "A Group of Children Wearing Helmets and Smiling for a Photo",
      keywords: ["children", "group", "helmet", "safety", "outdoors"],
      type: "ai",
    },
    {
      file: "ai16.webp",
      title: "An Adorable Creature with Large Expressive Eyes",
      keywords: ["cute", "creature", "animal", "eyes", "adorable"],
      type: "ai",
    },
    {
      file: "photo10.webp",
      title: "A Woman Cycling Past a Vibrant Market on a Bustling Street",
      keywords: ["woman", "bike", "cycling", "street", "market"],
      type: "photo",
    },
    {
      file: "photo9.webp",
      title: "A Cat Sitting on a Colorful Rug with a Leash",
      keywords: ["cat", "rug", "leash", "pet", "animal"],
      type: "photo",
    },
    {
      file: "photo13.webp",
      title: "A Person Sewing a Piece of Fabric with a Needle and Thread",
      keywords: ["sewing", "fabric", "needle", "thread", "handmade"],
      type: "photo",
    },
    {
      file: "ai3.webp",
      title: "A Rustic Cabin Nestled in the Heart of a Lush Forest Landscape",
      keywords: ["painting", "forest", "cabin", "trees", "nature"],
      type: "ai",
    },
    {
      file: "photo20.webp",
      title: "A Stylish Man Wearing Sunglasses and a Brown Jacket",
      keywords: ["man", "sunglasses", "fashion", "style", "jacket"],
      type: "photo",
    },
    {
      file: "photo16.webp",
      title: "Delicious Pancakes with Berries and a Cup of Tea",
      keywords: ["pancakes", "berries", "tea", "breakfast", "brunch"],
      type: "photo",
    },
    {
      file: "photo17.webp",
      title: "A Man Posing with a Lemon Covering His Eye",
      keywords: ["woman", "doughnut", "food", "snack", "dessert"],
      type: "photo",
    },
    {
      file: "photo15.webp",
      title: "A Woman Taking a Relaxing Bath in a Bathtub",
      keywords: ["woman", "bathtub", "bath", "relaxation", "hygiene"],
      type: "photo",
    },
    {
      file: "illustration7.webp",
      title: "A Bearded Man Holding a Flask with a Mischievous Expression",
      keywords: ["cartoon", "man", "flask", "red beard", "alcohol"],
      type: "illustration",
    },
    {
      file: "ai8.webp",
      title: "A Female Astronaut and a Robot Standing Together in Outer Space",
      keywords: ["woman", "space suit", "astronaut", "robot", "technology"],
      type: "ai",
    },
    {
      file: "photo14.webp",
      title: "Muffins on a White Plate Beside a Green Potted Plant",
      keywords: ["muffins", "white plate", "potted plant", "food", "breakfast"],
      type: "photo",
    },
    {
      file: "illustration3.webp",
      title: "A Beautiful Bonsai Tree in a Decorative Pot",
      keywords: ["bonsai", "tree", "pot", "plant", "gardening"],
      type: "illustration",
    },
    {
      file: "ai6.webp",
      title:
        "A Woman Holding a Rodent up to Her Face With a Curious Expression",
      keywords: ["woman", "rodent", "pet", "curiosity", "animal"],
      type: "ai",
    },
    {
      file: "photo18.webp",
      title: "A Man Riding a Motorcycle in Front of a Bustling Market",
      keywords: ["motorcycle", "man", "market", "street", "urban"],
      type: "photo",
    },
    {
      file: "ai7.webp",
      title: "A Person Standing in a Room with a Neon Light",
      keywords: ["person", "room", "neon light", "interior", "design"],
      type: "ai",
    },
    {
      file: "illustration8.webp",
      title:
        "A Cityscape with a Busy Road and Towering Buildings in the Background",
      keywords: ["city", "urban", "road", "street", "buildings"],
      type: "illustration",
    },
    {
      file: "photo5.webp",
      title: "A Street Cleaner Sweeping the Road With a Broom",
      keywords: ["street cleaner", "cleaning", "broom", "road", "pavement"],
      type: "photo",
    },
    {
      file: "ai20.webp",
      title: "A Bearded Man Holding a Basketball",
      keywords: ["man", "beard", "basketball", "sport", "athlete"],
      type: "ai",
    },
    {
      file: "ai18.webp",
      title: "A Cute Dog Sitting on a Desk in a Cozy Room",
      keywords: ["dog", "pet", "animal", "cute", "adorable"],
      type: "ai",
    },
    {
      file: "illustration4.webp",
      title:
        "Jesus Holding a Lamb and Surrounded by Sheep in a Beautiful Painting",
      keywords: ["painting", "Jesus", "lamb", "sheep", "religious"],
      type: "illustration",
    },
    {
      file: "ai9.webp",
      title: "A Cute Yellow Plush Toy Sitting on a Rocky Surface",
      keywords: ["stuffed animal", "plush toy", "yellow", "rock", "cute"],
      type: "ai",
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "white",
        overflowX: "hidden",
      }}
    >
      <Typography
        variant="h3"
        fontWeight={"bold"}
        sx={{
          color: "#000",
          textAlign: "center",
          py: 5,
          borderTop: "1px solid #dcdcdc",
        }}
      >
        🚀 {t("landing.accelerate")}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "#000",
          textAlign: "center",
          px: { xs: "10%", md: "30%" },
        }}
      >
        ⚡<strong> {t("landing.section1Sentence1")}</strong>{" "}
        {t("landing.section1Sentence2")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 5,
          py: 5,
          overflowX: "hidden",
          width: `${15 * examples.length + 10 * (examples.length - 1)}rem`, // Calcula el ancho de la sección
          animation: `marquee ${examples.length / 0.5 + 5}s linear infinite`, // Agrega tiempo
          animationTimingFunction: "linear", // Ajusta la propiedad de tiempo de animación
          "@keyframes marquee": {
            "0%": {
              transform: "translateX(0%)",
            },
            "100%": {
              transform: `translateX(-50.35%)`, // Calcula la cantidad de porcentaje para mover la sección
            },
          },
        }}
      >
        {/* Duplica las imágenes para hacer la animación infinita */}
        {[...examples, ...examples].map((example, index) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "10rem",
              gap: 2,
              transition: "all 100ms, borderRadius 0ms",
              "&:hover": {
                transition: "all 100ms",
                borderRadius: "2rem",
                cursor: "pointer",
                zIndex: 10,
              },
            }}
            key={index}
          >
            <Box
              sx={{
                position: "relative",
                backgroundImage: `url(/assets/landing/examples/${example.file})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundColor: "black",
                width: "10rem",
                aspectRatio: "4 / 5",
                borderRadius: "2rem",
                outline: "5px #dcdcdc solid",
                py: 2,
                boxSizing: "border-box",
                transition: "all 100ms, borderRadius 0ms",
                "&:hover": {
                  outline: "5px #ff3a5c solid",
                  boxShadow:
                    "0px 0px 20px #ff3a5c, 0px 20px 20px 0px rgba(0, 0, 0, 0.8), 0px 0px 60px 70px rgba(255, 255, 255, 1)",
                  transition: "all 100ms",
                  borderRadius: "2rem",
                  cursor: "pointer",
                  zIndex: 10,
                  mb: 2,
                  transform: "scale(1.1)",
                },
                "&:active": {
                  transform: "scale(0.9)",
                  boxShadow:
                    "0px 0px 20px #ff3a5c, 0px 0px 20px 0px rgba(0, 0, 0, 0.8), 0px 0px 60px 70px rgba(255, 255, 255, 1)",
                },
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  backgroundColor: "white",
                  maxWidth: "2rem",
                  maxHeight: "2rem",
                  width: "2rem",
                  height: "2rem",
                  top: "1rem",
                  left: "1rem",
                  display: "none",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "100%",
                  outline: "3px #dcdcdc solid",
                }}
              >
                {(() => {
                  if (example.type === "photo") {
                    return <CameraAltIcon htmlColor="black" />;
                  } else if (example.type === "illustration") {
                    return <BrushIcon htmlColor="black" />;
                  } else if (example.type === "vector") {
                    return <ShapeLineIcon htmlColor="black" />;
                  } else if (example.type === "ai") {
                    return <AutoAwesomeIcon htmlColor="black" />;
                  }
                })()}
              </Box>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  transition: "all ease-in-out 200ms",
                  opacity: 0,
                  "&:hover": {
                    opacity: 1,
                    height: "130%",
                  },
                }}
              >
                {[...example.keywords.slice(0, 5), "+35 keywords"].map(
                  (keyword, i) => (
                    <Box
                      sx={{
                        position: "absolute",
                        top: `${-8 + (50 * i) / 3.5}%`,
                        right: `90%`,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        borderRadius: "1rem",
                        overflow: "hidden",
                        boxSizing: "border-box",
                      }}
                      key={i}
                    >
                      <Chip
                        label={keyword}
                        size="small"
                        key={i}
                        style={{
                          margin: `0.2rem`,
                          backgroundColor: `black`,
                          color: "#fff",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                        sx={{
                          padding: ".2rem",
                          transition: "all 80ms ease-out",
                        }}
                        onDelete={() => {
                          if (session?.stashedUser) {
                            session?.loadUserFromStash();
                          } else {
                            navbar.setSelectedNavItem("login");
                            navbar.setOpenNavModal(true);
                          }
                        }}
                      />
                    </Box>
                  )
                )}
              </Box>
            </Box>
            <Typography
              variant="subtitle2"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                color: "black",
                zIndex: 90,
                userSelect: "none",
                pointerEvents: "none",
                width: "120%",
                alignSelf: "center",
              }}
            >
              {example.type === "photo"
                ? "📸"
                : example.type === "illustration"
                ? "🖌️"
                : example.type === "ai"
                ? "✨"
                : example.type === "vector" && "✒️"}{" "}
              {example.title}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default LandingExamples;
