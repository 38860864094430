import { useTranslation } from "react-i18next";
import { Fragment, useState, useCallback } from "react";
import {
  Box,
  Button,
  Snackbar,
  Alert,
  Tooltip,
  IconButton,
  Typography,
  LinearProgress,
} from "@mui/material";
import ModalItem from "./modalItem";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useDropzone } from "react-dropzone";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import CircularProgress from "@mui/material/CircularProgress";

const DownloadFiles = ({
  albumData,
  albumName,
  albumLoading,
  addMetadata,
  isMobile,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [compressing, setCompressing] = useState(false);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 1500) {
      setError(t("export.tooManyFiles"));
      return;
    }

    const filteredFiles = acceptedFiles.filter(
      (file) => file.type === "image/jpeg" || file.type === "image/png"
    );

    if (filteredFiles.length === 0) {
      setError(t("export.unsupportedFiles"));
      return;
    } else if (filteredFiles.length !== acceptedFiles.length) {
      setError(t("export.skippingUnsupportedFiles"));
    }

    setFiles(filteredFiles);
  };

  const getMetadata = useCallback(
    (name) => {
      if (!albumData) return null;
      return albumData.find((item) => item.name === name);
    },
    [albumData]
  );

  const processFilesInBatches = useCallback(async () => {
    setLoading(true);
    setProgress(0);
    setCompressing(false);
    const zip = new JSZip();
    const totalFiles = files.length;
    let atLeastOneFileZipped = false;
    let atLeastOneFileError = false;
    let missingFileWarning = false;
    let failedFileWarning = false;
    let processedFiles = 0;

    const processFile = async (file, fileIndex) => {
      try {
        const metadata = getMetadata(file.name);

        if (metadata) {
          const updatedFile = await addMetadata(
            file,
            metadata.title,
            metadata.description,
            metadata.keywords.join(", "),
            fileIndex + 1,
            totalFiles
          );
          if (!updatedFile) {
            throw new Error("Error adding metadata to file");
          }
          zip.file(file.name, updatedFile);
          atLeastOneFileZipped = true;
        } else if (!missingFileWarning) {
          missingFileWarning = true;
          setError(t("export.skippingMissingFiles"));
        }
      } catch (err) {
        atLeastOneFileError = true;
        if (!failedFileWarning) {
          failedFileWarning = true;
          setError(t("export.skippingFailedFiles"));
        }
      }
    };

    const batchSize = 5;

    for (let i = 0; i < totalFiles; i += batchSize) {
      const batch = files.slice(i, i + batchSize);

      const currentProcessedFiles = processedFiles;

      await Promise.all(
        batch.map((file, index) =>
          processFile(file, currentProcessedFiles + index)
        )
      );
      processedFiles += batch.length;
      setProgress(Math.floor((processedFiles / totalFiles) * 100));
    }

    if (!atLeastOneFileZipped) {
      if (atLeastOneFileError) {
        setError(t("export.processingError"));
      } else {
        setError(t("export.noFiles"));
      }
      setLoading(false);
    } else {
      setCompressing(true);
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, `metadata_${albumName}.zip`);
        setLoading(false);
        setProgress(100);
        setCompressing(false);
      });
    }
  }, [files, t, getMetadata, albumName, addMetadata]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
    multiple: true,
  });

  const handleCloseError = () => {
    setError("");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <Fragment>
        <ModalItem
          title={
            <>
              {t("export.files")}
              <Tooltip title={t("export.explanation")} arrow>
                <IconButton size="small" sx={{ ml: 1 }}>
                  <HelpOutlineIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          }
          primaryContent={t("export.selectPhotos")}
          dark={true}
        >
          {!isMobile && (
            <div {...getRootProps({ className: "dropzone" })}>
              <input
                {...getInputProps({
                  multiple: true,
                  accept: "image/jpeg, image/png",
                })}
              />
              <Button
                variant="contained"
                endIcon={<UploadFileIcon />}
                sx={{
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
                disabled={files.length > 0}
              >
                {files.length === 0
                  ? t("export.uploadPhotos")
                  : `${files.length} ${t("export.filesUploaded")}`}
              </Button>
            </div>
          )}
        </ModalItem>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "0.5rem",
            position: "sticky",
            bottom: "2rem",
            zIndex: 5,
            mt: "auto",
          }}
        >
          <Button
            fullWidth
            variant="contained"
            endIcon={
              loading || albumLoading ? (
                <CircularProgress size={20} />
              ) : (
                <FileDownloadIcon />
              )
            }
            sx={{
              fontWeight: "bold",
            }}
            onClick={processFilesInBatches}
            disabled={loading || albumLoading || isMobile || files.length === 0}
          >
            {compressing
              ? t("export.compressing")
              : loading
              ? `${progress}%`
              : !albumLoading
              ? t("export.downloadFiles")
              : ""}
          </Button>
        </Box>
        {isMobile && (
          <Typography variant="body1" color="#FCFCFC" textAlign={"center"}>
            {t("export.noMobile")}
          </Typography>
        )}
      </Fragment>
      {!compressing && loading && (
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ width: "100%", mb: "1rem" }}
        />
      )}
      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={handleCloseError}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          sx={{
            width: "80%",
          }}
        >
          <Alert
            onClose={handleCloseError}
            severity="error"
            sx={{
              width: "100%",
            }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default DownloadFiles;
