import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { StripeBundles, StripeCustom } from "./StripePlans";
import { SessionContext } from "../context/SessionContext";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import CurrencySelect from "./CurrencySelect";

const Plans = ({ supabase, blocked = false, customID = undefined }) => {
  const { t } = useTranslation();
  const session = useContext(SessionContext);

  useEffect(() => {
    if (session?.user?.id) {
      const getCustomerSecret = async () => {
        try {
          const {
            data: { session: supabaseSession },
          } = await supabase.auth.getSession();
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/customer-session`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${supabaseSession.access_token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                customerEmail: session?.user?.email,
                clientReferenceId: session?.user?.id,
              }),
            }
          );
          const { data, error } = await response.json();

          if (!error && data) {
            const timestamp = Date.now();
            session?.setCustomerSecret(data);
            session?.setCustomerSecretTimestamp(timestamp);
          }
        } catch (e) {}
      };
      if (
        !session?.customerSecret ||
        !session?.customerSecretTimestamp ||
        session?.customerSecretTimestamp < Date.now() - 30 * 60 * 1000
      ) {
        getCustomerSecret();
      }
    }
  }, [
    session?.user?.id,
    session?.customerSecret,
    session?.customerSecretTimestamp,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {!customID && (
        <Typography
          variant="h4"
          color="initial"
          fontWeight="bold"
          align="center"
          sx={{
            width: "80%",
            padding: { xs: "0 2rem" },
            mb: 2,
          }}
        >
          {blocked ? t("plan.blocked") : t("plan.purchase")}
        </Typography>
      )}
      <Box
        sx={{
          width: { xs: "90%", md: "100%" },
          minWidth: { xs: "15rem", md: "40rem" },
        }}
      >
        {session?.customerSecret ? (
          customID ? (
            <StripeCustom
              userID={session?.user?.id ?? null}
              customID={customID}
              customerSecret={session?.customerSecret}
            />
          ) : (
            <StripeBundles
              userID={session?.user?.id ?? null}
              isEU={session?.currentCurrency === "EUR"}
              customerSecret={session?.customerSecret}
            />
          )
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              minHeight: "20rem",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      {!customID && <CurrencySelect color="#0074d4" />}
    </Box>
  );
};

export default Plans;
