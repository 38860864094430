import React, { useState, useEffect, Fragment, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SessionContext } from "../context/SessionContext";
import { NavbarContext } from "../context/NavbarContext";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableSortLabel from "@mui/material/TableSortLabel";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "./Trends.css";

function Trends({ supabase }) {
  const { t } = useTranslation();
  const session = useContext(SessionContext);
  const navbar = useContext(NavbarContext);
  const [todayTrends, setTodayTrends] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("growth");

  function tiempoTranscurrido(desde) {
    const fechaDesde = new Date(desde);
    const diferencia = Date.now() - fechaDesde.getTime();
    const unidadesDeTiempo = [
      { nombre: "year", conversion: 365 * 24 * 60 * 60 * 1000 },
      { nombre: "month", conversion: 30.44 * 24 * 60 * 60 * 1000 },
      { nombre: "week", conversion: 7 * 24 * 60 * 60 * 1000 },
      { nombre: "day", conversion: 24 * 60 * 60 * 1000 },
      { nombre: "hour", conversion: 60 * 60 * 1000 },
      { nombre: "minute", conversion: 60 * 1000 },
      { nombre: "second", conversion: 1000 },
    ];
    for (let unidad of unidadesDeTiempo) {
      const cantidad = Math.floor(diferencia / unidad.conversion);
      if (cantidad > 0) {
        //return `Hace ${cantidad} ${unidad.nombre}${cantidad === 1 ? '' : 's'}`;
        return `Last updated ${cantidad} ${unidad.nombre}${
          cantidad === 1 ? "" : "s"
        } ago`;
      }
    }
    return "Few seconds ago";
  }

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedKeywords = todayTrends?.[0]?.keywords?.sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (order === "asc") {
      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
    } else {
      if (aValue > bValue) return -1;
      if (aValue < bValue) return 1;
    }

    return 0;
  });

  useEffect(() => {
    const getTrends = async () => {
      try {
        let response;
        if (session?.user?.id) {
          const {
            data: { session: supabaseSession },
          } = await supabase.auth.getSession();
          response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/get-trends`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${supabaseSession.access_token}`,
                "Content-Type": "application/json",
              },
            }
          );
        } else {
          response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/get-trends-free`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        }
        const json = await response.json();
        const { data, error } = json;
        if (!error) {
          setTodayTrends(data);
        }
      } catch (error) {}
    };
    getTrends();
  }, [session.user, supabase.auth]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>PhotoTag.ai - {t("trendsHelmet.headingSubtext")}</title>
        <meta
          name="description"
          content={t("trendsHelmet.headingDescription")}
        />
        <meta
          property="og:title"
          content={"PhotoTag.ai - " + t("trendsHelmet.headingSubtext")}
        />
        <meta
          property="og:description"
          content={t("trendsHelmet.headingDescription")}
        />
        <meta
          property="og:image"
          content="https://login.phototag.ai/storage/v1/object/public/assets/thumbnail.jpg"
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="PhotoTag.ai" />
        <meta property="og:type" content="website" />
        <meta
          name="twitter:title"
          content={"PhotoTag.ai - " + t("trendsHelmet.headingSubtext")}
        />
        <meta
          name="twitter:description"
          content={t("trendsHelmet.headingDescription")}
        />
        <meta
          name="twitter:image"
          content="https://login.phototag.ai/storage/v1/object/public/assets/thumbnail.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${window.location.href}"
              },
              "headline": "PhotoTag.ai - ${t("trendsHelmet.headingSubtext")}",
              "description": "${t("trendsHelmet.headingDescription")}",
              "image": "https://login.phototag.ai/storage/v1/object/public/assets/thumbnail.jpg",
              "publisher": {
                "@type": "Organization",
                "name": "PhotoTag.ai",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://phototag.ai/assets/logo.png"
                }
              }
            }
          `}
        </script>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Box
        sx={{
          background: "linear-gradient(to right, #d3cce3, #e9e4f0)",
          backgroundSize: "cover",
          backgroundPosition: "right",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          mt: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: {
              xs: "20% 10%",
              sm: "6rem 15%",
              md: "5% 15%",
              lg: "5% 15%",
              xl: "2% 15%",
            },
            width: { xs: "90vw", md: "100%" },
            aspectRatio: { xs: "9 / 16", md: "16 / 9" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "center",
              width: { xs: "100%", lg: "80%", xl: "60%" },
              bgcolor: "white",
              outline: "5rem solid white",
            }}
          >
            <Typography
              variant="h3"
              color="initial"
              fontWeight="bold"
              sx={{ align: "left", color: "#000", mt: 5 }}
            >
              {todayTrends?.[0]?.title}
            </Typography>
            <Box
              sx={{
                aspectRatio: "16/9",
                backgroundImage: `url(${todayTrends?.[0]?.thumbnail})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                width: "100%",
                borderRadius: "2rem",
              }}
            />
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img
                src="./assets/keywi_small.jpg"
                alt=""
                style={{
                  width: "2rem",
                  height: "2rem",
                  borderRadius: "100%",
                }}
              />
              <Typography variant="body1" color="initial">
                by Keywi
              </Typography>
              <Typography variant="body1" color="gray">
                {tiempoTranscurrido(todayTrends?.[0]?.created_at)}
              </Typography>
            </Box>

            <Typography
              color="initial"
              sx={{
                align: "left",
                mt: 1,
              }}
            >
              {t("trends.mainMessage")}{" "}
              <strong>{t("trends.salutation")}</strong>
            </Typography>

            <Typography
              variant="h2"
              fontWeight={"bold"}
              fontSize={"1.5rem"}
              align="left"
              color="initial"
            >
              {t("trends.heading")}
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                backgroundColor: "#fafafa", // Fondo blanco claro
              }}
            >
              <Table aria-label="caption table">
                <TableHead
                  sx={{ borderColor: "#dcdcdc", backgroundColor: "#121216" }}
                >
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        color: "white",
                        borderColor: "#dcdcdc",
                        fontWeight: "bold",
                      }}
                    >
                      {t("trends.keyword")}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "white",
                        borderColor: "#dcdcdc",
                        fontWeight: "bold",
                      }}
                    >
                      {t("trends.agency")}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        color: "white",
                        borderColor: "#dcdcdc",
                        fontWeight: "bold",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === "growth"}
                        direction={orderBy === "growth" ? order : "asc"}
                        onClick={() => handleSort("growth")}
                      >
                        {t("trends.growth")}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        color: "white",
                        borderColor: "#dcdcdc",
                        fontWeight: "bold",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === "demand"}
                        direction={orderBy === "demand" ? order : "asc"}
                        onClick={() => handleSort("demand")}
                      >
                        {t("trends.demand")}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        color: "white",
                        borderColor: "#dcdcdc",
                        fontWeight: "bold",
                      }}
                    >
                      {t("trends.explore")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedKeywords?.map(
                    (keyword, index) =>
                      keyword.keyword && (
                        <TableRow
                          key={keyword.keyword + index}
                          sx={{ border: "1px solid #dcdcdc" }}
                        >
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: "1.2rem",
                              textTransform: "capitalize",
                              color: "black",
                              borderColor: "#dcdcdc",
                            }}
                          >
                            {keyword.locked && "🔒 "}
                            {keyword.keyword}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: "1.2rem",
                              textTransform: "capitalize",
                              color: "black",
                              borderColor: "#dcdcdc",
                            }}
                          >
                            <AvatarGroup max={6}>
                              {keyword.agency?.map((agency, agencyIndex) => (
                                <Avatar
                                  key={agencyIndex}
                                  alt={agency}
                                  src={`./assets/trends/agencies/${agency}.png`}
                                  sx={{
                                    width: 24,
                                    height: 24,
                                    bgcolor: "white",
                                    border: "none !important",
                                  }}
                                  title={agency}
                                />
                              ))}
                            </AvatarGroup>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              color: "black",
                              borderColor: "#dcdcdc",
                              fontWeight: "bold",
                            }}
                          >
                            {keyword.growth}%
                          </TableCell>
                          <TableCell
                            align="left"
                            className={
                              keyword.demand?.includes("high")
                                ? "trend_high"
                                : ""
                            }
                            sx={{
                              textTransform: "capitalize",
                              color: "black",
                              borderColor: "#dcdcdc",
                              fontWeight: "bold",
                            }}
                          >
                            {keyword.demand === "very high"
                              ? "🔥"
                              : keyword.demand === "high"
                              ? "🟢"
                              : keyword.demand === "medium"
                              ? "🟡"
                              : keyword.demand === "regular"
                              ? "🟠"
                              : "🔴"}
                            {` ${keyword.demand}`}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ borderColor: "#dcdcdc" }}
                          >
                            <HashLink
                              sx={{
                                color: "unset",
                                textDecoration: "unset",
                                display: "flex",
                                alignItems: "center",
                                gap: "0.2rem",
                                "&:hover": {
                                  textDecoration: "none",
                                  cursor: "pointer",
                                },
                              }}
                              to={
                                !keyword.locked
                                  ? `https://www.shutterstock.com/search/${keyword.keyword?.replace(
                                      " ",
                                      "-"
                                    )}`
                                  : "/trends"
                              }
                              target={keyword.locked ? "_self" : "_blank"}
                              rel="noopener noreferrer"
                            >
                              <Button
                                variant={keyword.locked ? "contained" : "text"}
                                color={keyword.locked ? "secondary" : "primary"}
                                className={keyword.locked ? "gradient-bg2" : ""}
                                sx={{
                                  color: keyword.locked && "white",
                                  backgroundColor: keyword.locked
                                    ? "#FF5722"
                                    : "transparent",
                                  fontWeight: "bold",
                                }}
                                onClick={
                                  keyword.locked
                                    ? () => {
                                        if (!session?.user?.id) {
                                          if (session?.stashedUser) {
                                            session?.loadUserFromStash(
                                              "/trends"
                                            );
                                          } else {
                                            navbar.setSelectedNavItem("login");
                                            navbar.setOpenNavModal(true);
                                          }
                                        } else {
                                          localStorage.setItem(
                                            "goToCheckout",
                                            true
                                          );
                                          session?.setCheckStorageFromTrends(
                                            true
                                          );
                                        }
                                      }
                                    : () => {}
                                }
                              >
                                {keyword.locked
                                  ? t("trends.unlock")
                                  : t("trends.view")}
                              </Button>
                            </HashLink>
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}

export default Trends;
