import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SessionContext } from "../context/SessionContext";
import { NavbarContext } from "../context/NavbarContext";
import Button from "@mui/material/Button";
import AlbumsTable from "../components/AlbumsTable";
import LogIn from "./LogIn";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";

function Albums({ supabase }) {
  const itemsPerPage = 20;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const session = useContext(SessionContext);
  const navbar = useContext(NavbarContext);
  const [userAlbums, setUserAlbums] = useState([]);
  const [filteredUserAlbums, setFilteredUserAlbums] = useState([]);
  const [disableExport, setDisableExport] = useState(true);
  const [albumsLoading, setAlbumsLoading] = useState(true);
  const [pagesLoaded, setPagesLoaded] = useState(5);
  const [userAlbumsCount, setUserAlbumsCount] = useState(0);

  useEffect(() => {
    if (userAlbums?.length !== userAlbumsCount) {
      setUserAlbumsCount(userAlbums.length);
    }
  }, [userAlbums, userAlbumsCount]);

  useEffect(() => {
    if (
      (session?.subStatus && session?.subStatus?.[0]?.id !== "free") ||
      session?.bundleCreditsAllowed > 0
    ) {
      setDisableExport(false);
    }
  }, [session?.subStatus, session?.bundleCreditsAllowed]);

  const actions = [
    {
      name: t("albums.exportResults"),
      slug: "album-export",
      multiple: true,
      disabled: false,
      action: function (ids) {
        if (disableExport) {
          navbar.setOpenNavModal(true);
          navbar.setSelectedNavItem("more-photos-blocked");
        } else {
          navbar.setOpenNavModal(ids);
          navbar.setSelectedNavItem("download");
        }
      },
      icon: DownloadIcon,
    },
    {
      name: t("albums.copyLink"),
      slug: "album-copy",
      multiple: false,
      disabled: false,
      action: function (id) {
        navigator.clipboard.writeText(
          `${process.env.REACT_APP_PRIMARY_URL}/result/${id}`
        );
      },
      icon: InsertLinkOutlinedIcon,
    },
    /*{
      name: "Rename (Soon)",
      slug: "album-rename",
      multiple: false,
      disabled: true,
      action: function (album) {
        navbar.setOpenNavModal(true);
        navbar.setSelectedNavItem(this.slug);
      },
      icon: DriveFileRenameOutlineOutlinedIcon,
    },*/
    {
      name: t("albums.delete"),
      slug: "album-delete",
      color: "error",
      multiple: true,
      disabled: false,
      action: function (ids) {
        navbar.setOpenNavModal(ids);
        navbar.setSelectedNavItem(this.slug);
      },
      icon: DeleteOutlineOutlinedIcon,
    },
  ].map((action) => ({ ...action, action: action.action.bind(action) }));

  useEffect(() => {
    const getData = async () => {
      if (userAlbumsCount >= pagesLoaded * itemsPerPage - 1) {
        return;
      }
      let { data, error } = await supabase
        .from("album")
        .select("*")
        .eq("created_by", session?.user?.id)
        .order("created_at", { ascending: false })
        .range(userAlbumsCount, pagesLoaded * itemsPerPage - 1);
      if (!error) {
        setUserAlbums((prev) => [...prev, ...data]);
        setFilteredUserAlbums((prev) => [...prev, ...data]);
        setAlbumsLoading(false);
      }
    };
    if (session?.user?.id) {
      getData();
    }
  }, [session?.user?.id, supabase, navigate, pagesLoaded, userAlbumsCount]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    session?.setSubChanged(true);
  }, [session]);

  return session?.user ? (
    <Box
      sx={{
        background: "linear-gradient(to right, #d3cce3, #e9e4f0)",
        backgroundSize: "contain",
        backgroundPosition: "right",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        width: "100%",
        minHeight: "100vh",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          justifyContent: "flex-start",
          alignItems: "center",
          mt: 10,
          padding: {
            xs: "20% 10%",
            sm: "6rem 15%",
            md: "5% 15%",
            lg: "5% 15%",
            xl: "2% 15%",
          },
          width: { xs: "80vw", md: "90vw" },
          aspectRatio: { xs: "9 / 16", md: "16 / 9" },
        }}
      >
        <Typography
          variant="h3"
          color="initial"
          fontWeight="bold"
          sx={{ align: "left", color: "#000", mb: 1 }}
        >
          {t("albums.albums")}
        </Typography>

        <Box
          sx={{
            width: "95%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ fontWeight: "bold" }}
            onClick={() => navigate("/upload")}
          >
            + {t("albums.newAlbum")}
          </Button>
        </Box>

        <AlbumsTable
          session={session}
          filteredUserAlbums={filteredUserAlbums}
          setFilteredUserAlbums={setFilteredUserAlbums}
          userAlbums={userAlbums}
          navigate={navigate}
          actions={actions}
          disableExport={disableExport}
          albumsLoading={albumsLoading}
          pagesLoaded={pagesLoaded}
          setPagesLoaded={setPagesLoaded}
          itemsPerPage={itemsPerPage}
        />
      </Box>
    </Box>
  ) : (
    <LogIn customPath={"/albums"} />
  );
}

export default Albums;
